import { Component, OnInit, NgZone } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
import axios from "axios";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Organizacion } from "src/app/models/organizacion";
import { ConstantsService } from "src/app/constants.service";
@Component({
  selector: "admin",
  templateUrl: "./adminhome.component.html",
})
export class AdminHomeComponent {
  public title: string;
  public rute: string;
  public organizacion_reg: Organizacion;
  public closeResult = "";
  public _enviar: boolean = false;
  successMessage = "";
  public typealert: string = "";
  private _success = new Subject<string>();
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService
  ) {
    this.title = "Inicio";
    this.rute = "/inicio";

    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
  }

  ngOnInit() {
    this.constants.listado_ciudades();
    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(5000))
      .subscribe(() => (this.successMessage = ""));
  }

  async Contactanos(captchaResponse: string) {
    // var name = $scope.negocio_prov;
    // console.log("name is " + name);
    this.typealert = "danger";
    if (this.organizacion_reg.org_empresa.trim() == "") {
      this._success.next("Ingrese nombre de negocio");
      return false;
    }

    if (this.organizacion_reg.org_representante.trim() == "") {
      this._success.next("Ingrese nombre de representante");
      return false;
    }

    if (this.organizacion_reg.org_email.trim() == "") {
      this._success.next("Ingrese email");
      return false;
    }

    // if (this.organizacion_reg.org_mensaje.trim() == "") {
    //   this._success.next("Ingrese mensaje");
    //   return false;
    // }
    this._enviar = true;
    let request = {
      empresa: this.organizacion_reg.org_empresa,
      representante: this.organizacion_reg.org_representante,
      telefono: this.organizacion_reg.org_telefono,
      email: this.organizacion_reg.org_email,
      pais: this.organizacion_reg.org_pais,
      ciudad: this.organizacion_reg.org_ciudad,
      mensaje: this.organizacion_reg.org_mensaje,
      tokenrecaptcha: captchaResponse,
    };

    console.log("CONTACTANOS: " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "contactanos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "success").then(() => {
            this.organizacion_reg = new Organizacion(
              0,
              "",
              "",
              "",
              "",
              0,
              "",
              0,
              "Perú",
              "",
              "",
              false,
              false,
              false,
              false,
              "",
              "",
              false,
              null,
              null,
              null,
              null
            );
          });
        } else {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }
}
