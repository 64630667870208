export class Beneficio {
  constructor(
    public ben_id: number,
    public ben_codigo: string,
    public ben_descripcion: string,
    public ben_cantidadcompra: any,
    public ben_cantidadbeneficio: any,
    public ben_descuento: any,
    public ben_precioactual: any,
    public ben_preciocompra: any,
    public ben_idProducto: number,
    public ben_idProveedor: number,
    public ben_tipo: number,
    public ben_vigencia: boolean,
    public ben_fechainicio: Date,
    public ben_fechafin: Date,
    public ben_fecharegistro: Date,
    public ben_adminregistro: number,
    public ben_fechaactualiza: Date,
    public ben_adminactualiza: number,
    public ben_producto: string,
    public ben_tipodesc: string,
    public ben_imagen_large: any,
    public ben_imagen_small: any,
    public ben_small_url: any,
    public ben_large_url: any
  ) {}
}

export class beneficioProducto {
  constructor(
    public bp_id: number,
    public bp_cantidad: any,
    public bp_idproducto: number,
    public bp_idbeneficio: number,
    public bp_dona: boolean,
    public bp_estado: boolean,
    public bp_fecharegistro: Date,
    public bp_adminregistro: number,
    public bp_fechactualiza: Date,
    public bp_adminactualiza: number,
    public bp_producto: string,
    public bp_beneficio: string,
    public bp_categoria: string
  ) {}
}
