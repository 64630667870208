import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "../../models/usuario";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Producto } from "src/app/models/producto";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Categoria } from "src/app/models/categoria";
const swal: SweetAlert = _swal as any;
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Descuento } from "src/app/models/descuento";
import { ExportExcelService } from "src/app/services/export-excel.service";
@Component({
  selector: "producto",
  templateUrl: "./producto.component.html",
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class ProductoComponent implements OnInit {
  public title: string;
  public rute: string;
  public productos_lst: Array<Producto>;
  public productos_fil: Array<Producto>;
  public producto: Producto;
  public producto_reg: Producto;
  public _banregistro: boolean = false;
  public _bandescuento: boolean = false;
  public _banregistrodesc: boolean = false;
  public searchinput: string = "";
  public tipo: number = 0;
  public errimagen: boolean = false;
  public imagen_log: string;
  public categoriaproducto_lst: Array<Categoria>;
  public categoria_lst: Array<Categoria>;
  public subcategoria_lst: Array<Categoria>;
  public categoriadesc_lst: Array<Categoria>;
  hoveredDate: NgbDate;
  public small_log: string;
  public large_log: string;
  fromDate: NgbDate;
  toDate: NgbDate;
  public errsmall: boolean = false;
  public errlarge: boolean = false;

  public descuento_reg: Descuento;
  public descuentos_lst: Array<Descuento>;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public ete: ExportExcelService
  ) {
    this.title = "Productos";
    this.rute = "/productos";
    this.constants.sesionadmin();
    this.constants.usuario_log = JSON.parse(
      localStorage.getItem("logindatos_cas")
    );

    console.log("USUARIO BUS " + JSON.stringify(this.constants.usuario_log));
    this.productos_lst = new Array<Producto>();
    this.descuentos_lst = new Array<Descuento>();
    this.productos_fil = new Array<Producto>();
    this.categoriaproducto_lst = new Array<Categoria>();
    this.categoria_lst = new Array<Categoria>();
    this.subcategoria_lst = new Array<Categoria>();
    this.categoriadesc_lst = new Array<Categoria>();
    this.categoriadesc_lst.push(
      new Categoria(
        0,
        "",
        "Seleccione...",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );
    this.producto = new Producto(
      0,
      "",
      "",
      "",
      0,
      0.0,
      0.0,
      "",
      0,
      "",
      0,
      "",
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      "",
      0,
      0
    );
    this.producto_reg = new Producto(
      0,
      "",
      "",
      "",
      0,
      0.0,
      0,
      "",
      0,
      "",
      0,
      "",
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      "",
      0,
      0
    );
  }
  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();
    this.listado_productos();
    this.listado_categoria_productos();
    this.constants.listado_categoria(1);
    this.constants.listado_categoria(2);
  }

  search_producto($txt: string) {
    if (this.tipo == 0) {
      $txt = $txt.toLocaleLowerCase();
      this.productos_lst = new Array<Producto>();
      this.productos_lst = this.productos_fil.filter(
        (producto: Producto) =>
          producto.prod_descripcion.toLocaleLowerCase().indexOf($txt) !== -1
      );
    } else if (this.tipo == 1) {
      $txt = $txt.toLocaleLowerCase();
      this.productos_lst = new Array<Producto>();
      this.productos_lst = this.productos_fil.filter(
        (producto: Producto) =>
          producto.prod_codigo.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }else if (this.tipo == 2) {
      $txt = $txt.toLocaleLowerCase();
      this.productos_lst = new Array<Producto>();
      this.productos_lst = this.productos_fil.filter(
        (producto: Producto) =>
          producto.prod_categoria.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }
  }
  public lista_productos_refresh() {
    this.searchinput = "";
    this.search_producto("");
  }

  public nuevo_registro() {
    this._banregistro = true;
    this.imagen_log = "";
    this.errimagen = false;
    this.producto_reg = new Producto(
      0,
      "",
      "",
      "",
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      "",
      0,
      "",
      0,
      "",
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      "",
      0,
      0
    );
  }

  limpiar_listados_prod() {
    this.productos_lst = new Array<Producto>();
    this.productos_fil = new Array<Producto>();
  }

  async listado_productos() {
    this.spinner.show();
    this.limpiar_listados_prod();
    let request = {};
    request = {
      id: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_productos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let productos_lst_aux = response.data.data;
        let prodcuto_aux: Producto;
        if (response.data.estado) {
          if (productos_lst_aux != null) {
            for (let element in productos_lst_aux) {
              //console.log("num " + element);

              prodcuto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].imagen,
                parseFloat(productos_lst_aux[element].cantidad).toFixed(2),
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                parseFloat(productos_lst_aux[element].preciototal).toFixed(2),
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                productos_lst_aux[element].adminactualiza,
                this.constants.apiUrl +
                  "storage?ruta=" +
                  productos_lst_aux[element].imagen,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                0
              );
              this.productos_fil.push(prodcuto_aux);
            }
            this.productos_lst = this.productos_fil;
          }
        } else {
          swal("Registro de productos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_categoria_productos() {
    this.spinner.show();
    this.limpiar_listados_prod();
    let request = {};
    request = {
      id: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista__categoria_productos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let categoria_lst_aux = response.data.data;
        let categoria_aux: Categoria;
        if (response.data.estado) {
          if (categoria_lst_aux != null) {
            this.categoria_lst.push(
              new Categoria(
                0,
                "",
                "Seleccione...",
                false,
                0,
                0,
                null,
                0,
                null,
                0,
                "",
                ""
              )
            );
            this.subcategoria_lst.push(
              new Categoria(
                0,
                "",
                "Seleccione...",
                false,
                0,
                0,
                null,
                0,
                null,
                0,
                "",
                ""
              )
            );
            for (let element in categoria_lst_aux) {
              //console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia == 1 ? true : false,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                null,
                0,
                null,
                0,
                "",
                ""
              );
              if (categoria_lst_aux[element].tipo == "CATEGORIA") {
                this.categoria_lst.push(categoria_aux);
              }
              this.categoriaproducto_lst.push(categoria_aux);
            }
          }
          this.productos_lst = this.productos_fil;
        } else {
          swal("Registro de productos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  seleccionar_superior($id: number, $ban: boolean) {
    let categoria_aux: Categoria;
    this.subcategoria_lst = new Array<Categoria>();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "Seleccione...",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );

    if ($id > 0) {
      console.log("SUPERIOR " + $id);
      this.categoriaproducto_lst.forEach((item, index) => {
        if (item.cat_superior == $id) {
          categoria_aux = new Categoria(
            item.cat_id,
            item.cat_codigo,
            item.cat_descripcion,
            item.cat_vigencia,
            item.cat_superior,
            item.cat_idCategoria,
            null,
            0,
            null,
            0,
            "",
            ""
          );
          this.subcategoria_lst.push(categoria_aux);
        }
      });
    }
    if (!$ban) {
      this.producto_reg.prod_idCategoria = 0;
    }
  }

  public cancelar_registro() {
    this._banregistro = false;
  }

  async deshabilitar_habilitar($producto: Producto, $estado: boolean) {
    this.spinner.show();
    this.producto_reg = $producto;
    let request = { id: this.producto_reg.prod_id, vigencia: $estado };
    await axios
      .post(this.constants.apiUrl + "estado_producto", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de Producto", response.data.mensaje, "success").then(
            () => {
              this.listado_productos();
              this._banregistro = false;
            }
          );
        } else {
          swal("Registro de Producto", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  public editar($producto: Producto) {
    this.producto_reg = new Producto(
      0,
      "",
      "",
      "",
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      "",
      0,
      "",
      0,
      "",
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      "",
      0,
      0
    );
    this.producto_reg = $producto;
    this.imagen_log = this.producto_reg.prod_imagen;
    if (this.imagen_log) {
      this.errimagen = true;
    }
    this.seleccionar_superior(this.producto_reg.prod_superior, true);
    this._banregistro = true;
  }

  async guardar_producto() {
    this.spinner.show();
    let imagen: any = null;
    if (this.errimagen) {
      if (this.imagen_log == "") {
        imagen = this.producto_reg.prod_imagen.files[0];
      } else {
        imagen = null;
      }
    }
    console.log(imagen);
    let request = new FormData();
    request.append("id", this.producto_reg.prod_id.toString());
    request.append("codigo", this.producto_reg.prod_codigo.toString().trim());
    request.append("descripcion", this.producto_reg.prod_descripcion.toString().trim());
    request.append("idEnvase", this.producto_reg.prod_idEnvase.toString());
    request.append("idUnidad", this.producto_reg.prod_idUnidad.toString());
    request.append("precio", this.producto_reg.prod_precio);
    request.append("cantidad", "1");
    request.append("idProveedor", "0");
    request.append("imagen", imagen);
    request.append(
      "idCategoria",
      this.producto_reg.prod_idCategoria.toString()
    );
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_producto", request, {
        headers: this.constants.headers_formimage,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de Producto", response.data.mensaje, "success").then(
            () => {
              this.listado_productos();
              this._banregistro = false;
            }
          );
        } else {
          swal("Registro de Producto", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  limpiar_adj($id: string) {
    console.log($id);
    this.producto_reg.prod_imagen = null;
    if (
      this.producto_reg.prod_imagen === "" ||
      this.producto_reg.prod_imagen === null ||
      this.producto_reg.prod_imagen === undefined
    ) {
      this.errimagen = false;
    } else {
      this.errimagen = true;
    }
  }

  previewimage($file: any, $id: string) {
    if (localStorage.getItem("token_cas") !== null) {
      // let files = $file.files;
      // this.producto_reg.prod_imagen = files.files;
      this.producto_reg.prod_imagen = document.getElementById($id);
      console.log(this.producto_reg.prod_imagen);
      // if (files.length === 0) return;
      // let mimeType = files[0].type;
      // if (mimeType.match(/image\/*/) == null) {
      //   // this.message = "Only images are supported.";
      //   return;
      // }
      // let reader = new FileReader();
      // reader.readAsDataURL(files[0]);
      // reader.onload = _event => {
      //   console.log(reader.result);
      //   this.producto_reg.prod_imagen = document.getElementById($id);
      this.errimagen = true;
      this.imagen_log = "";
      //   console.log(this.producto_reg.prod_imagen);
      // this.empresa_reg._logofile = document.getElementById($id);
      // };
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/business/dashboardprov"]);
      });
    }
  }

  //FECHAS
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  limpiar_adj2($id: string) {
    // console.log($file);
    if ($id === "large") {
      this.producto_reg.prod_imagen = null;
      if (
        this.producto_reg.prod_imagen === "" ||
        this.producto_reg.prod_imagen === null ||
        this.producto_reg.prod_imagen === undefined
      ) {
        this.errlarge = false;
      } else {
        this.errlarge = true;
      }
    } else if ($id === "small") {
      this.producto_reg.prod_imagen_url = null;
      if (
        this.producto_reg.prod_imagen_url === "" ||
        this.producto_reg.prod_imagen_url === null ||
        this.producto_reg.prod_imagen === undefined
      ) {
        this.errsmall = false;
      } else {
        this.errsmall = true;
      }
    }
  }

  previewimage2($file: any, $id: string) {
    if (localStorage.getItem("token_cas") !== null) {
      // let files = $file.files;

      console.log("ID " + $id);
      if ($id === "large") {
        // this.beneficio_reg.ben_imagen_large = files.files;
        this.producto_reg.prod_imagen = document.getElementById($id);
        this.errlarge = true;
        this.large_log = "";
      } else if ($id === "small") {
        // this.beneficio_reg.ben_imagen_small = files.files;
        this.producto_reg.prod_imagen_url = document.getElementById($id);
        this.errsmall = true;
        this.small_log = "";
      }
      console.log(this.producto_reg.prod_imagen);
      console.log(this.producto_reg.prod_imagen_url);
      // if (files.length === 0) return;
      // let mimeType = files[0].type;
      // if (mimeType.match(/image\/*/) == null) {
      //   // this.message = "Only images are supported.";
      //   return;
      // }
      // let reader = new FileReader();
      // reader.readAsDataURL(files[0]);
      // reader.onload = _event => {
      //   if ($id == "large") {
      //     console.log("large");
      //     this.beneficio_reg.ben_imagen_large = reader.result;
      //     this.errlarge = true;
      //   } else if ($id == "small") {
      //     console.log("small");
      //     this.beneficio_reg.ben_imagen_small = reader.result;
      //     this.errsmall = true;
      //   }
      //   // this.empresa_reg._logofile = document.getElementById($id);
      // };
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/business/dashboardprov"]);
      });
    }
  }

  async descuentos($producto: Producto) {
    this.producto_reg = $producto;
    this.title = "Descuentos de producto";
    this._bandescuento = true;
    this._banregistrodesc = false;
  }

  async regresar_productos() {
    this._bandescuento = false;
    this.title = "Productos";
  }

  nuevo_descuento() {
    this.descuento_reg = new Descuento(
      0,
      "",
      "",
      null,
      null,
      0,
      0,
      null,
      null,
      null,
      null,
      false,
      null,
      0,
      null,
      0,
      "",
      ""
    );
    this._banregistrodesc = true;
    this.errsmall = false;
    this.errlarge = false;
    this.small_log = "";
    this.large_log = "";
  }

  cancelar_descuento() {
    this._banregistrodesc = false;
  }

  editardescuento($descuento: Descuento) {
    this.descuento_reg = $descuento;
    this._banregistrodesc = true;
    this.small_log = this.descuento_reg.desc_imgSmall;
    this.large_log = this.descuento_reg.desc_imgLarge;
    if (this.small_log) {
      this.errsmall = true;
    }
    if (this.large_log) {
      this.errlarge = true;
    }
  }

  async guardar_descuento() {}

  deshabilitar_habilitar_descuento(desc: Descuento, estado: boolean) {}
  async listado_descuentos() {}

  dataForExcel = [];
  empPerformance = []
  ExportToExcel()
  {
    this.empPerformance = [];
    this.productos_lst.forEach((item) => {
      this.empPerformance.push({ "CODIGO":item.prod_codigo.toString(), "PRODUCTO":item.prod_descripcion,"CATEGORÍA":item.prod_categoria, "PRECIO": parseFloat(item.prod_precio).toFixed(2)})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    let d = new Date();
    let date = d.getDate().toString().padStart(2,"0") + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getFullYear();
    let reportData = {
      title: 'Lista de Productos',
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Lista de productos descargado el día "
    }

    this.ete.exportExcel(reportData);


  }
}
