import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

"use strict";
@Component({
  selector: "services",
  templateUrl: "./services.component.html",
})
export class ServicesComponent {
  private _name = "";
  public titulo: string;
  public ruta: string;
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;

  constructor(
    // private _router : ActivatedRoute,
    private router: Router
  ) {}
}
