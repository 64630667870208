export class Pedido {
  constructor(
    public id: number,
    public idProveedor: number,
    public fechaPedido: any,
    public idEmpresa: number,
    public idTienda: number,
    public direccion: string,
    public estado: number,
    public beneficio: any,
    public total: any,
    public igv: any,
    public subtotal: any,
    public vigencia: boolean,
    public idUsuRegistro: number,
    public fecharegistro: any,
    public idProvActualiza: number,
    public fechaactualiza: any,
    public proveedor: string,
    public descestado: string,
    public precioAtendido: any,
    public pago: any,
  ) {}
}

export class DetallePedido {
  constructor(
    public id: number,
    public idPedido: number,
    public idProducto: number,
    public cantidad: any,
    public cantidadAtendida: any,
    public cantidadRecibidaAct: any,
    public cantidadRecibida: any,
    public cantidadFaltante: any,
    public precioUnit: any,
    public precioTotal: any,
    public vigencia: boolean,
    public producto: string,
    public precioAtendido: any,
    public preciovender: any,
    public idProveedor: number,
    public unidades: any
  ) {}
}
