import {Component,  Input, } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector:'services_ad',
  templateUrl:'./services_ad.component.html'
})

export class ServicesADComponent {
  private _name='';
  public titulo:string;
  public ruta:string;
  @Input() tituloEvent : string;
  @Input() ruteEvent : string;
  constructor(
    // private _router : ActivatedRoute,
    private router:Router
  ){  }



}
