import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "src/app/constants.service";
import { Categoria } from "src/app/models/categoria";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "categorias",
  templateUrl: "./categoria.component.html",
})
export class CategoriaComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipo_bus: string = "Categoría";
  public categoria_reg: Categoria;
  public categoria_lst: Array<Categoria>;
  public categoria_fil: Array<Categoria>;
  public searchinput: string;
  public reg_categoria_b: boolean = false;
  public _ver: boolean = true;
  public _ver_subcat: boolean = false;
  public _edit: boolean = true;
  public _ir_a: string = "subcategoría";
  public id_categoria: number;
  public categoria_lst_cbo: Array<Categoria>;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Categorías y subcategorías";
    this.rute = "/categorias";
    this.constants.sesionadmin();
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }

  ngOnInit() {
    // console.log("login.component cargado!");
    this.categoria_lst = new Array<Categoria>();
    this.categoria_lst_cbo = new Array<Categoria>();
    this.categoria_fil = new Array<Categoria>();
    this.listado_categoria(false);
    // console.log(this.categoria_lst);
  }

  seleccionar_categoria($id: number) {
    this.id_categoria = $id;
    this.listado_categoria(true);
  }
  search_categoria($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.categoria_lst = new Array<Categoria>();
    this.categoria_lst = this.categoria_fil.filter(
      (categoria: Categoria) =>
        categoria.cat_descripcion.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }
  editar($categoria: Categoria) {
    this._ver = false;
    this._edit = false;
    this.categoria_reg = $categoria;
  }
  agregarsubcategoria($categoria: Categoria) {
    this.id_categoria = $categoria.cat_id;
    this._ver = true;
    this.tipo_bus = "subcategoría";
    this._ir_a = "categoría";
    this._ver_subcat = true;
    this.listado_categoria(true);
  }
  async habilitar_deshabilitar($categoria: Categoria, $estado: boolean) {
    this.spinner.show();
    let request = { id: $categoria.cat_id, vigencia: $estado };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_categoria", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        // console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de " + this.tipo_bus,
            response.data.mensaje,
            "success"
          ).then(() => {
            this.refrescar();
          });
        } else {
          swal("Registro de " + this.tipo_bus, response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        // console.log(error);
      });
  }

  cancelar() {
    this._ver = true;
    this._edit = true;
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }
  nuevo() {
    this._ver = false;
    this._edit = false;
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }

  refrescar() {
    if (this._ir_a == "categoría") {
      this.listado_categoria(true);
    } else {
      this.listado_categoria(false);
    }
  }
  ir_a() {
    if (this._ir_a == "categoría") {
      this.tipo_bus = "categoría";
      this._ir_a = "subcategoría";
      this._ver_subcat = false;
      this.listado_categoria(false);
    } else {
      this.tipo_bus = "subcategoría";
      this._ir_a = "categoría";
      this._ver_subcat = true;
      this.listado_categoria(true);
    }
    this._edit = true;
    this._ver = true;
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }

  async listado_categoria(_superior: boolean) {
    this.spinner.show();
    this.categoria_lst = new Array<Categoria>();
    if (!_superior) {
      this.categoria_lst_cbo = new Array<Categoria>();
    }
    let request = {
      id: _superior ? this.id_categoria : 4,
      superior: _superior,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        // console.log("categoria \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              // console.log("num " + element);
              this.categoria_lst.push(categoria_aux);
              this.categoria_fil = this.categoria_lst;
              if (!_superior) {
                this.categoria_lst_cbo.push(categoria_aux);
              }
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        // else {
        //   swal("Registro de " + this.tipo_bus, response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        // console.log(error);
      });
  }

  async registrar() {
    if (this.categoria_reg.cat_codigo.trim() == "") {
      swal("Registro de " + this.tipo_bus, "Ingrese código", "info");
      return false;
    }
    if (this.categoria_reg.cat_descripcion.trim() == "") {
      swal("Registro de " + this.tipo_bus, "Ingrese descirpción", "info");
      return false;
    }
    this.spinner.show();
    let request = {
      id: this.categoria_reg.cat_id,
      descripcion: this.categoria_reg.cat_descripcion,
      codigo: this.categoria_reg.cat_codigo,
      superior: this._ir_a == "categoría" ? this.id_categoria : 0,
      idCategoria: this._ir_a == "categoría" ? 5 : 4,
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_categoria", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        // console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de " + this.tipo_bus,
            response.data.mensaje,
            "success"
          ).then(() => {
            this.cancelar();
            this.refrescar();
          });
        } else {
          swal("Registro de " + this.tipo_bus, response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        // console.log(error);
      });
  }
}
