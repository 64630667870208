import {Component,  Input} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector:'titulo',
  templateUrl:'./titulo.component.html'
})

export class TituloComponent {
  public _name='';
  public titulo:string;
  public ruta:string;
  @Input() tituloEvent : string;
  @Input() ruteEvent : string;
  constructor(
    // public _router : ActivatedRoute,
    public router:Router
  ){  }


}
