import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;

import {
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbModal,
  ModalDismissReasons,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";

import { Pedido, DetallePedido } from "src/app/models/pedido";
import { Organizacion } from "src/app/models/organizacion";
import { DetalleOrden, Orden } from "src/app/models/orden";
import { ExportExcelService } from "src/app/services/export-excel.service";

@Component({
  selector: "pedidobus",
  templateUrl: "./pedidobus.component.html",
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class PedidoBusComponent implements OnInit {
  public title: string;
  public rute: string;
  public _cantprod: number = 0;
  public precioventa: number = 0;
  public tipopago: number = 0;
  public pago: any = 0;
  public swpago: boolean = false;
  public _show: boolean = true;
  public _disabled: boolean = false;
  public _showedit: boolean = false;
  public _showeditdet: boolean = false;
  public _showdetalle: boolean = true;
  public _showdlista: boolean = true;
  // public _numlst_lg: string = "12";
  // public _numlst_md: string = "12";
  public _verpedido: boolean = false;
  public _model: any;
  public _comstants_producto: any;
  public modelproducto: any;
  public cantidadprod: any = 0;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  hoveredDate: NgbDate;
  model: NgbDateStruct;
  placement = "left";
  public fechaventa: NgbDate;
  public minFecha: NgbDate;
  public maxFecha: NgbDate;
fechafin:any;
fechainicio:any;
  public proveedor_lst: Array<Organizacion>;
  public proveddor_reg: Organizacion;
  public lstpedido: Array<Pedido>;
  public prodpedido_lst: Array<DetallePedido>;
  public pedido: Pedido;
  public orden_lst: Array<Orden>;
  public closeResult = "";
  public orden_reg: Orden;
  public detorden_lst: Array<DetalleOrden>;
  public deuda: any;
  public montocobro: any;

  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private el: ElementRef,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    public ete: ExportExcelService
  ) {
    this.title = "Pedidos";
    this.rute = "/business/pedidos";
    // this.constants.permisotda_existe(this.rute);
    this.constants.sesionbusiness();
  }

  ngOnInit() {
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );

    this.fechaventa = this.calendar.getToday();
    this.minFecha = this.calendar.getToday();
    this.maxFecha = this.calendar.getToday();
    this.lstpedido = new Array<Pedido>();
    this.proveedor_lst = new Array<Organizacion>();
    this.prodpedido_lst = new Array<DetallePedido>();
    this.orden_lst = new Array<Orden>();
    this._cantprod = 0;

    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0, 0
    );

    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = new Orden(
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      true,
      0,
      null,
      0,
      null,
      0,
      "",
      "",
      0,
      0
    );
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -10);
    this.fechainicio = this.fromDate.year + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + this.fromDate.day.toString().padStart(2, "0")
    this.fechafin =  this.toDate.year + "-" + this.toDate.month.toString().padStart(2, "0") + "-" + this.toDate.day.toString().padStart(2, "0")
    this.listarpedidos();
  }

  nuevo() {
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0.0, 0
    );

    this._show = true;
    this._disabled = false;
    this._showedit = false;
    this._showeditdet = false;
    this._showdetalle = true;
    this._showdlista = true;
    this._verpedido = false;
    this._cantprod = 0;
    this.prodpedido_lst = new Array<DetallePedido>();
    this.fechaventa = this.calendar.getToday();

    this.tipopago = 0;
  }

  editar() {
    this._showedit = true;
    this._showdlista = false;
    this._showdetalle = true;
  }

  eliminar(estado: boolean) {}

  listar() {
    this._showdlista = true;
    this._showdetalle = false;
    this._show = false;
    this._verpedido = false;
  }

  async registrar($state: boolean) {
    let detpedido: Array<DetallePedido>;
    detpedido = new Array<DetallePedido>();
    let cant: any = 0;
    let cant_at: any = 0;
    console.log(this.prodpedido_lst);
    this.prodpedido_lst.forEach((item) => {
      cant = parseFloat(cant) + parseFloat(item.cantidad);
      cant_at =
        parseFloat(cant_at) +
        (parseFloat(item.cantidadAtendida) + parseFloat(item.cantidadRecibida));
    });

    let data = {
      idPedido: $state ? 0 : this.pedido.id,
      estado: cant == cant_at ? 5 : 4,
      detallepedido: this.prodpedido_lst.filter(
        (detpedi) => parseFloat(detpedi.cantidadRecibida) > 0.0
      ),
    };
    console.log("PEDIDO : " + JSON.stringify(data));
    this.spinner.show();
    await axios
      .post(this.constants.apiUrl + "atender_pedido", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          swal("Pedidos", response.data.mensaje, "success").then(() => {
            this.cancelar();
          });
        } else {
          swal("Pedidos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
      });

    this.nuevo();
  }

  cancelar() {
    this._verpedido = false;
    let cant_at: any = 0;
    console.log(this.prodpedido_lst);
    this.prodpedido_lst.forEach((item) => {
      cant_at = parseFloat(cant_at) + parseFloat(item.cantidadAtendida);
    });
    let _st: number = 0;
    if (cant_at == 0) {
      _st = 3;
    } else {
      _st = this.pedido.estado;
    }
    this.cambiarestado(this.pedido, _st).then(() => {
      this.verlista();
    });
  }

  verlista() {
    this._show = false;
    this._showdetalle = false;
    this.listarpedidos();
  }

  agregar() {
    let _ban = true;
    let prod_cantidad = this.cantidadprod;
    this.prodpedido_lst.forEach((item) => {
      if (item.idProducto == this.modelproducto.prod_id) {
        _ban = false;
      }
    });
    if (_ban) {
      if (prod_cantidad <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese cantidad para " + this.modelproducto.prod_descripcion,
          "info"
        );
      } else if (this.precioventa <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese precio sugeridad que venderá el producto por unidad",
          "info"
        );
      } else {
        this.modelproducto.prod_preciototal = (
          prod_cantidad * this.modelproducto.prod_precio
        ).toFixed(2);

        this.prodpedido_lst.push(
          new DetallePedido(
            0,
            0,
            this.modelproducto.prod_id,
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            0,
            parseFloat(this.modelproducto.prod_precio).toFixed(2),
            parseFloat(
              (prod_cantidad * this.modelproducto.prod_precio).toString()
            ).toFixed(2),
            true,
            this.modelproducto.prod_descripcion,
            0,
            parseFloat(this.precioventa.toString()).toFixed(2),
            0,
            this.orden_reg.idProveedor == 0
              ? 1
              : parseFloat(this.modelproducto.prod_cantidad).toFixed(2)
          )
        );
        // this.dataSource = this.prodpedido_lst;
      }
      if (this.prodpedido_lst.length > 0) {
        this._showedit = true;
      }
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    } else {
      swal(
        "Registro de pedidos",
        "Producto " +
          this.modelproducto.prod_descripcion +
          " ya ha sido agregado.",
        "info"
      );
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    }
    if (this.prodpedido_lst.length == 1) {
      this._cantprod = this.proveddor_reg.org_id;
    }
  }

  agregar_predido() {
    this._show = false;
    this._showdlista = false;
    this._showedit = true;
    this._verpedido = true;
    this.listarpedidos();
  }

  getTotalCost() {
    return this.prodpedido_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCost() {
    let total = this.getTotalCost();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCost() {
    let total = this.getTotalCost();
    return (
      parseFloat(this.getTotalCost()) - parseFloat(this.getSubTotalCost())
    ).toFixed(2);
  }

  async listarpedidos() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let request = {
      idProveedor: this.constants.usuariobus_log.us_idProveedor.toString(),
      token: this.constants.usuariobus_log.us_token,
      fechainicio: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      fechafin:  this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_pedidos_poratender");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_pedidos_poratender", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            for (let element in pedido_lst_aux) {
              let pedido: Pedido = new Pedido(
                pedido_lst_aux[element].id,
                pedido_lst_aux[element].idProveedor,
                pedido_lst_aux[element].fechaPedido,
                pedido_lst_aux[element].idEmpresa,
                pedido_lst_aux[element].idTienda,
                pedido_lst_aux[element].direccion,
                pedido_lst_aux[element].estado,
                pedido_lst_aux[element].beneficio,
                parseFloat(pedido_lst_aux[element].total).toFixed(2),
                parseFloat(pedido_lst_aux[element].igv).toFixed(2),
                parseFloat(pedido_lst_aux[element].subtotal).toFixed(2),
                pedido_lst_aux[element].vigencia == 1 ? true : false,
                pedido_lst_aux[element].idUsuRegistro,
                pedido_lst_aux[element].fecharegistro,
                pedido_lst_aux[element].idProvActualiza,
                pedido_lst_aux[element].fechaactualiza,
                pedido_lst_aux[element].empresa,
                this.constants.estado_pedido[
                  parseInt(pedido_lst_aux[element].estado)
                ],
                parseFloat(pedido_lst_aux[element].precioatendida).toFixed(2),
                parseFloat(pedido_lst_aux[element].pago).toFixed(2)
              );
              this.lstpedido.push(pedido);
            }
          }
        } else {
          swal("Datos de tiendas ", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async ingresoproducto($pedido: Pedido) {
    this._verpedido = true;
    this.pedido = $pedido;
    let _st: number = 0;
    if (this.pedido.estado == 0 || this.pedido.estado == 3) {
      _st = 2;
    } else {
      _st = $pedido.estado;
    }
    this.cambiarestado(this.pedido, _st).then(() => {
      this.listardetalle($pedido.id);
    });
  }

  async listardetalle($idpedido: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let request = {
      idpedido: $idpedido,
      token: this.constants.usuariobus_log.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detallepedido_atender");
    await axios
      .post(this.constants.apiUrl + "lista_detallepedido_atender", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.prodpedido_lst = new Array<DetallePedido>();
        if (response.data.estado) {
          for (let element in detalle_lst_aux) {
            this.prodpedido_lst.push(detalle_lst_aux[element]);
          }
        } else {
          swal("Detalle de pedido ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de pedido!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  edit_detail($state: boolean) {
    if (!$state) {
      this.prodpedido_lst.forEach((item) => {
        item.precioTotal = (item.cantidadRecibida * item.precioUnit).toFixed(2);
      });
    }
    this._showeditdet = $state;
  }

  edit_detail2($detped: DetallePedido) {
    console.log($detped.cantidadRecibida);
    console.log($detped.cantidadRecibidaAct);
    if (parseFloat($detped.cantidadRecibida) > parseFloat($detped.cantidadRecibidaAct)) {
      swal(
        "Ingreso de productos",
        "Cantidad por atender excede a lo solicitado.",
        "info"
      );
      $detped.cantidadRecibida = $detped.cantidadRecibidaAct;
      return false;
    }

    this.prodpedido_lst.forEach((item) => {
      item.precioAtendido = (item.cantidadRecibida * item.precioUnit).toFixed(
        2
      );
    });
  }

  // async pagar() {
  //   if (parseFloat(this.montocobro) > parseFloat(this.deuda)) {
  //     swal("Pagos", "El monto a pagar es mayor a la deuda", "info");
  //   } else {
  //     let tienda_aux: Tienda = JSON.parse(
  //       localStorage.getItem("datostienda_tda")
  //     );
  //     let data = {
  //       idOrden: this.orden_reg.id,
  //       fecha:
  //         this.fechaventa["year"] +
  //         "-" +
  //         this.fechaventa["month"] +
  //         "-" +
  //         this.fechaventa["day"],
  //       tipo: false,
  //       idTienda: tienda_aux.tda_id,
  //       montopago: this.montocobro,
  //     };
  //     // this.spinner.show();
  //     console.log("COBRO: " + JSON.stringify(data));

  //     await axios
  //       .post(this.constants.apiUrl + "registro_cobropago", data, {
  //         headers: this.constants.headers_aut,
  //       })
  //       .then((response) => {
  //         console.log(response.data);
  //         this.spinner.hide();
  //         if (response.data.estado) {
  //           swal("Pagos", response.data.mensaje, "success").then(() => {
  //             this.listar();
  //             this.modalService.dismissAll();
  //           });
  //         } else {
  //           swal("Pagos", response.data.mensaje, "info");
  //         }
  //       })
  //       .catch(function (error) {
  //         this.spinner.hide();
  //         // swal("Ha ocurrido un error al registrar!", error.message, "error");
  //         console.log("tipo error " + error);
  //       });
  //   }
  // }

  // verpagar(content, $venta: Orden) {
  //   this.detorden_lst = new Array<DetalleOrden>();
  //   this.orden_reg = $venta;
  //   this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
  //   this.orden_reg.total = parseFloat($venta.total).toFixed(2);
  //   this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
  //   let totalamort: number =
  //     parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
  //   this.deuda = ($venta.total - totalamort).toFixed(2);
  //   this.montocobro = this.deuda;
  //   this.listardetalleorden($venta.id);
  //   this.modalService
  //     .open(content, { ariaLabelledBy: "modal-basic-title" })
  //     .result.then(
  //       (result) => {
  //         this.closeResult = `Closed with: ${result}`;
  //       },
  //       (reason) => {
  //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       }
  //     );
  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async cambiarestado($pedido: Pedido, $estado: number) {
    let data = {
      idpedido: $pedido.id,
      estado: $estado,
    };
    console.log(JSON.stringify(data));
    await axios
      .post(this.constants.apiUrl + "estado_pedido", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.pedido.estado = $estado;
        this.pedido.descestado = this.constants.estado_pedido[$estado];
        console.log(response.data.mensaje);
      })
      .catch(function (error) {
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }


  //FECHAS
  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  dataForExcel = [];
  empPerformance = []
  ExportToExcel()
  {
    this.empPerformance = [];
    this.lstpedido.forEach((item) => {
      this.empPerformance.push({ "FECHA":item.fechaPedido.substr(0, 10), "TIPO PAGO":item.descestado,"CLIENTE":item.proveedor, "MONTO": parseFloat(item.total).toFixed(2)})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    let d = new Date();
    let date = d.getDate().toString().padStart(2,"0") + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getFullYear();
    let reportData = {
      title: 'Lista de Productos',
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Lista de productos descargado el día "
    }

    this.ete.exportExcel(reportData);


  }

  async ExportToPDF($id){
    window.open(
      this.constants.apiUrl +
        "descargar-pedido?idPedido=" +
        $id,
      "_self"
    );

  }
}
