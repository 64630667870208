export class Orden {
  constructor(
    public id: number,
    public numero: number,
    public idProveedor: number,
    public idCliente: number,
    public idPedido: number,
    public idVenta: number,
    public idTienda: number,
    public fecha: any,
    public tipoPago: number,
    public montopago: any,
    public montodevuelto: any,
    public subtotal: any,
    public igv: any,
    public total: any,
    public tipoorden: number,
    public vigencia: boolean,
    public idUsuRegistro: number,
    public fecharegistro: any,
    public idProvActualiza: number,
    public fechaactualiza: any,
    public cierrecaja: any,
    public proveedor: string,
    public cliente: string,
    public montodevuelto_act: any,
    public deuda: any
  ) {}
}

export class DetalleOrden {
  constructor(
    public id: number,
    public idOrden: number,
    public idProducto: number,
    public idProveedor: number,
    public tipo: number,
    public cantidad: any,
    public cantidaddev: any,
    public precioUnit: any,
    public precioTotal: any,
    public precioVentaUnid: any,
    public vigencia: any,
    public producto: string,
    public cantidad_act: any,
    public cantidaddev_act: any,
    public precioDevolver: any
  ) {}
}
