import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "inform_ad",
  templateUrl: "./inform_ad.component.html",
})
export class InformAdComponent {
  private _name = "";
  public titulo: string;
  public ruta: string;
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router
  ) {
    this.loadScripts();
  }
  loadScripts() {
    const dynamicScripts = [
      "https://ajax.googleapis.com/ajax/libs/angular_material/1.1.12/angular-material.min.css",
      "https://ajax.googleapis.com/ajax/libs/angularjs/1.7.6/angular.min.js",
      "https://ajax.googleapis.com/ajax/libs/angularjs/1.7.6/angular-animate.min.js",
      "https://ajax.googleapis.com/ajax/libs/angularjs/1.7.6/angular-aria.min.js",
      "https://ajax.googleapis.com/ajax/libs/angularjs/1.7.6/angular-messages.min.js",
      "https://ajax.googleapis.com/ajax/libs/angular_material/1.1.12/angular-material.min.js",
      "../../../../assets/scripts/functions.js",
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }
}
