export class HorarioEntrega {
  constructor(
    public id: number,
    public idProveedor: number,
    public ubigeo: string,
    public dia: string,
    public horainicio: string,
    public horafin: string,
    public turno: string,
    public vigencia: boolean,
    public departamento: string,
    public provincia: string,
    public distrito: string
  ) {}
}
