export class Personal {
  constructor(
    public per_id: number,
    public per_nombre: string,
    public per_apellidopaterno: string,
    public per_apellidomaterno: string,
    public per_email: string,
    public per_celular: string,
    public per_tienda: boolean,
    public per_idempresa: number,
    public per_vigencia: boolean,
    public per_fecharegistro: any,
    public per_adminregistro: number,
    public per_fechactualiza: any,
    public per_adminactualiza: number
  ) {}
}
