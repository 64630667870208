import {Component,  Input, } from '@angular/core';
// import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector:'videopublic',
  templateUrl:'./videopublic.component.html'
})

export class VideoPublicComponent {
  private _name='';
  public titulo:string;
  public ruta:string;
  @Input() tituloEvent : string;
  @Input() ruteEvent : string;
  constructor(
    // private _router : ActivatedRoute,
    //private router:Router
  ){  }



}
