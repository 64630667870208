export class Descuento {
  constructor(
    public desc_id: number,
    public desc_codigo: string,
    public desc_descripcion: string,
    public desc_fechaInicio,
    public desc_fechaFin,
    public desc_idProducto: number,
    public desc_idCategoria: number,
    public desc_imgSmall: any,
    public desc_imgLarge: any,
    public desc_imgSmall_url: any,
    public desc_imgLargel_url: any,
    public desc_vigencia,
    public desc_fecharegistro: Date,
    public desc_adminregistro: number,
    public desc_fechaactualiza: Date,
    public desc_adminactualiza: number,
    public desc_categoria: string,
    public desc_producto: string
  ) {}
}
