import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "../../models/usuario";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Categoria } from "src/app/models/categoria";
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import axios from "axios";
import * as _swal from "sweetalert";
import { Tienda } from "src/app/models/tienda";
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from "ng2-charts";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import jsPDF from '../../../../node_modules/jspdf';
import html2canvas from '../../../../node_modules/html2canvas';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: "dashboardprov",
  templateUrl: "./dashboardbus.component.html",
})
export class DashboardBusComponent implements OnInit {
  public title: string;
  public rute: string;
  public closeResult = "";
  public categoria_lst: Array<Categoria>;
  public subcategoria_lst: Array<Categoria>;
  public categoriaproducto_lst: Array<Categoria>;
  public prod_idCategoria: number;
  public prod_superior: number;
  public tienda_lst: Array<Tienda>;
  public prod_tienda: number;
  public  heightcd:any
  chartOptions = {
    responsive: true
  };
  chartData = [
    { data: [330, 600, 260, 700], label: 'Account A' },
    { data: [120, 455, 100, 340], label: 'Account B' },
    { data: [45, 67, 800, 500], label: 'Account C' }
  ];
  chartLabels = ['January', 'February', 'Mars', 'April'];
  titled = 'TOP 10 DE PRODUCTOS MÁS VENDIDOS';
  type='ColumnChart';
  data = [];

 columnNames = ['Descripción', 'Producto'];
 options = {
  colors: ['#e0440e'], is3D: true
};

title2 = 'TOP 10 DE PRODUCTOS MÁS VENDIDOS';
  type2='Table';
  data2 = [];

 columnNames2 = ['Código','Descripción', 'Producto'];
 options2 = {
  alternatingRowStyle:true,
     showRowNumber:true
};
width = 600;
   height = 500;
   public barChartOptions: ChartOptions = {
    responsive: true,

  };
   public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = []
  public barChartLabels2: Label[] = [];
public barChartData2: ChartDataSets[] = []
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) {
    this.title = "Estadísticas";
    this.rute = "tda/estadisticas";
    this.constants.sesionbusiness();
    this.categoria_lst = new Array<Categoria>();
    this.subcategoria_lst = new Array<Categoria>();
    this.categoriaproducto_lst = new Array<Categoria>();
    this.tienda_lst = new Array<Tienda>();
    this.listar_tiendas();
    this.prod_tienda = 0;
    monkeyPatchChartJsTooltip()
    monkeyPatchChartJsLegend()
    setTimeout(() => {
      this.listar()

    }, 2500);
  }

  ngOnInit() {
    // this.top_productos();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );
    this.listado_categoria();
    this.listado_subcategoria();


  }

  async top_ciudades() {
    this.pieChartLabels = []
    this.pieChartData = []
    let request = { };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "top-ciudades", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("top-ciudades \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              this.pieChartLabels.push(product_aux[element].ciudad)
              this.pieChartData.push(product_aux[element].cantidad)

            }
          }
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }


  async top_productos() {
    let dataPoints = [];
    let dataPoints2 = [];
    this.data = [];
    this.data2 = [];
    this.barChartLabels = []
    this.barChartData = []
    let request = {
      idtienda: this.prod_tienda,
      subcategoria: this.prod_idCategoria,
      categoria: this.prod_superior
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "top-producto", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("top producto \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              //  console.log("num " + element);

              this.barChartLabels.push(product_aux[element].codigo)
              this.data.push(parseInt(product_aux[element].cantidad.toString()))
               dataPoints= [product_aux[element].codigo, parseInt(product_aux[element].cantidad.toString())];
               dataPoints2= [product_aux[element].codigo, product_aux[element].descripcion,  {v: parseInt(product_aux[element].cantidad.toString()), f: parseInt(product_aux[element].cantidad.toString())} ];
              // this.data.push(dataPoints)
              this.data2.push(dataPoints2)
            }

            this.barChartData=  [
              { data: this.data, label: 'Productos', borderColor: '#148c9f', backgroundColor: '#148c9f', hoverBackgroundColor: '#066F80'},
            ];

          }
          this.spinner.hide()
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }

  async pedidosproveedores() {
    let data = [];
    this.barChartLabels2 = []
    this.barChartData2 = []
    let request = {idProveedor: this.constants.usuariobus_log.us_idProveedor};
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "pedidos-proveedores", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("pedidos-proveedores \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              //  console.log("num " + element);
              this.barChartLabels2.push(product_aux[element].tienda)
              data.push(parseInt(product_aux[element].cantidad.toString()))
            }

             this.barChartData2=  [
              { data: data, label: 'Comercios', borderColor: '#148c9f', backgroundColor: '#148c9f', hoverBackgroundColor: '#066F80'},
            ];
          }
          this.spinner.hide()
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }
  async listado_categoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 4,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          this.categoria_lst.push(
            new Categoria(
              0,
              "",
              "TODOS",
              false,
              0,
              0,
              null,
              0,
              null,
              0,
              "",
              ""
            )
          );
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoria_lst.push(categoria_aux);
            }
          }
          this.prod_idCategoria = 0;
          this.prod_superior = 0;
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async listado_subcategoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 5,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoriaproducto_lst.push(categoria_aux);
            }
          }
          console.log("PRODUCTOS " + JSON.stringify(this.categoriaproducto_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  seleccionar_superior($id: number, $ban: boolean) {
    let categoria_aux: Categoria;
    this.subcategoria_lst = new Array<Categoria>();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );

    if ($id > 0) {
      console.log("SUPERIOR " + $id);
      this.categoriaproducto_lst.forEach((item, index) => {
        if (item.cat_superior == $id) {
          categoria_aux = new Categoria(
            item.cat_id,
            item.cat_codigo,
            item.cat_descripcion,
            item.cat_vigencia,
            item.cat_superior,
            item.cat_idCategoria,
            null,
            0,
            null,
            0,
            "",
            ""
          );
          this.subcategoria_lst.push(categoria_aux);
        }
      });
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  filtrar(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  async listar_tiendas() {
    this.spinner.hide();
    let request = {
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda_all");
    this.tienda_lst = new Array<Tienda>();
    this.tienda_lst.push(new Tienda(
      0,
      "TODOS",
     0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null,0
    ));
    await axios
      .post(this.constants.apiUrl + "lista_tienda_all", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre + '::' + tienda_lst_aux[element].empresa.toString().toUpperCase(),
              tienda_lst_aux[element].idNegocio,
              tienda_lst_aux[element].direccion,
              tienda_lst_aux[element].referencia,
              tienda_lst_aux[element].ciudad,
              tienda_lst_aux[element].vigencia,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado,
            );
            this.tienda_lst.push(tienda);
          }
        } else {
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  download($tipo:number) {
    this.spinner.show()
    // Extraemos el
    const DATA = document.getElementById('htmlData');
    const DATA2 = document.getElementById('htmlData2');
    const DATA3 = document.getElementById('htmlData3');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

      if($tipo==0){
      html2canvas(DATA, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        this.heightcd = pdfHeight;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        html2canvas(DATA2, options).then((canvas) => {

          const img = canvas.toDataURL('image/PNG');

          // Add image Canvas to PDF
          const bufferX = 15;
          const bufferY = this.heightcd + 50;
          const imgProps = (doc as any).getImageProperties(img);
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
          return doc;
        }).then((docResult) => {
          docResult.save(`${new Date().toISOString()}_top10productos.pdf`);
          this.spinner.hide()
        });
        // docResult.save(`${new Date().toISOString()}_top10productos.pdf`);
      });
    }else if($tipo==1){
      html2canvas(DATA3, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        this.heightcd = pdfHeight;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {

          docResult.save(`${new Date().toISOString()}top10ciudadestiendas.pdf`);
          this.spinner.hide()
        });
    }
  }

  async listar(){
    this.spinner.show()
    this.top_productos();
    this.top_ciudades()
    this.pedidosproveedores()

  }
}
