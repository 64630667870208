import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "../../models/usuario";
import { ConstantsService } from "../../constants.service";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
//import { CabeceraComponent } from '../plantilla/cabecera.component'
@Component({
  selector: "adminlogin",
  templateUrl: "./loginadmin.component.html",
})
export class AdminLoginComponent implements OnInit {
  public title: string;
  public usuario: Usuario;

  public closeResult = "";
  public _enviar: boolean = false;
  successMessage = "";
  public typealert: string = "";
  private _success = new Subject<string>();
  constructor(
    // public _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService, //public cabecera : CabeceraComponent
    public spinner: NgxSpinnerService
  ) {
    this.title = "Inicio de Sesión";
    this.usuario = new Usuario(
      0,
      "",
      "",
      true,
      0,
      0,
      0,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    //console.log(localStorage.getItem("logindatos_cas"));
    // console.log(localStorage.getItem("logindatos_cas"));

    if (localStorage.getItem("token_cas") !== null) {
      if (localStorage.getItem("logindatos_cas") !== null) {
        this.router.navigate(["dashboardadmin"]);
      } else {
        if (localStorage.getItem("recordar_cas") !== null) {
          let item = JSON.parse(localStorage.getItem("recordar_cas"));
          this.usuario.us_usuario = item.usuario;
          this.usuario.us_password = item.password;
          this.usuario.us_recordar = item.recordar;
        } else {
          this.usuario = new Usuario(
            0,
            "",
            "",
            true,
            0,
            0,
            0,
            "",
            null,
            null,
            null,
            null,
            "",
            "",
            "",
            "",
            false,
            0,
            "",
            "",
            "",
            ""
          );
        }
        localStorage.removeItem("logindatos_cas");
        this.router.navigate(["adminlogin"]);
      }
    } else {
      if (localStorage.getItem("recordar_cas") !== null) {
        let item = JSON.parse(localStorage.getItem("recordar_cas"));
        this.usuario.us_usuario = item.usuario;
        this.usuario.us_password = item.password;
        this.usuario.us_recordar = item.recordar;
      } else {
        this.usuario = new Usuario(
          0,
          "",
          "",
          true,
          0,
          0,
          0,
          "",
          null,
          null,
          null,
          null,
          "",
          "",
          "",
          "",
          false,
          0,
          "",
          "",
          "",
          ""
        );
      }
      localStorage.removeItem("logindatos_cas");
      this.router.navigate(["adminlogin"]);
    }
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(5000))
      .subscribe(() => (this.successMessage = ""));
  }

  public async loginadmin(captchaResponse: string) {
    let _ban: boolean = true;
    let _mensaje: string = "";

    this.typealert = "danger";
    if (
      this.usuario.us_usuario.trim() == "" &&
      this.usuario.us_password.trim() == ""
    ) {
      this._success.next("Ingrese sus credenciales.");
      return false;
    }
    if (this.usuario.us_usuario.trim() == "") {
      this._success.next("Ingrese usuario.");
      return false;
    }
    if (this.usuario.us_password.trim() == "") {
      this._success.next("Ingrese contraseña.");
      return false;
    }
    this._enviar = true;
    let data = {
      usuario: this.usuario.us_usuario,
      password: this.usuario.us_password,
      recordar: true,
      tokenrecaptcha: captchaResponse,
    };
    await axios
      .post(this.constants.apiUrl + "loginadmin", data)
      .then((response) => {
        let datos = response.data;
        if (datos.estado) {
          let dt_usuario = datos.user[0];
          this.usuario.us_usuario = dt_usuario.usuario;
          this.usuario.us_password = dt_usuario.password;
          this.usuario.us_id = dt_usuario.id;
          this.usuario.us_vigencia = dt_usuario.vigencia;
          this.usuario.us_idAdmnistrador = dt_usuario.idAdministrador;
          this.usuario.us_apellidopaterno = dt_usuario.apellidopaterno;
          this.usuario.us_apellidomaterno = dt_usuario.apellidomaterno;
          this.usuario.us_nombres = dt_usuario.nombres;
          this.usuario.us_token = datos.token;
          this.constants.usuario_log = this.usuario;
          console.log(
            "INICIO " + JSON.stringify(JSON.stringify(datos.user[0]))
          );
          localStorage.setItem("logindatos_cas", JSON.stringify(this.usuario));
          if (this.usuario.us_recordar === true) {
            localStorage.setItem("recordar_cas", JSON.stringify(data));
          } else {
            localStorage.removeItem("recordar_cas");
          }
          localStorage.setItem("token_cas", datos.token);
          this._enviar = false;
          swal("Inicio de sesión", datos.mensaje, "success").then(
            (response) => {
              this.router.navigate(["dashboardadmin"]);
            }
          );
        } else {
          this._enviar = false;
          swal("Inicio de sesión", datos.mensaje, "info").then((response) => {
            localStorage.removeItem("token_cas");
          });
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}
