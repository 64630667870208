export class Notificacion {
  constructor(
    public id: number,
    public fecha: any,
    public asunto: string,
    public mensaje: string,
    public idProveedor: number,
    public idTienda: number,
    public vigencia: boolean,
    public adminregistro: number,
    public fecharegistro: any,
    public adminactualiza: number,
    public fechaactualiza: any,
    public empresa: string
  ) {}
}
