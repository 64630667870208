import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "inform",
  templateUrl: "./inform.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class InformComponent {
  private _name = "";
  public titulo: string;
  public ruta: string;
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router
  ) {}
}
