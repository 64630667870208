import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "./models/usuario";
import { environment } from "./../environments/environment";
import axios from "axios";
import { APP_BASE_HREF } from "@angular/common";
import { Categoria } from './models/categoria';

@Injectable()
export class ConstantsService {
  constructor(private router: Router) {}
  readonly apiUrl: string = environment.URLAPI;
  readonly index: string = environment.INDEX;
  readonly indextienda: string = environment.INDEXTDA;
  readonly keyrecaptcha: string = environment.SITEKEY;
  public ciudad_lst:Array<Categoria> = new Array<Categoria>()
  public envase_lst:Array<Categoria> = new Array<Categoria>()
  public unidadmedida_lst:Array<Categoria> = new Array<Categoria>()
  public estado_pedido: Array<string> = [
    "REGISTRADO",
    "EDITANDO",
    "REVISANDO",
    "POR DESPACHAR",
    "ATENDIDO PARCIAL",
    "DESPACHADO",
    "RECIBIDO PARCIAL",
    "RECIBIDO",
    "CANCELADO",
  ];

  public negocio_array = [
    "Distribuidora artesanal",
    "Distribuidora varios productos de consumo",
    "Empresa industrializadora",
  ];
  public organizacion_array = ["Proveedor", "Auspiciador", "Patrocinador"];
  public sector_array = [
    "Zapatería",
    "Ropa",
    "Hoteles",
    "Comida",
    "Agentes financieros",
    "Otros",
  ];
  public headers = new Headers({
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  });

  public headers_aut = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token_cas"),
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  });

  public headers_formimage = new Headers({
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Bearer " + localStorage.getItem("token_bus"),
    // 'Access-Control-Allow-Origin': "*"
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  });

  public usuario_log: Usuario = JSON.parse(
    localStorage.getItem("logindatos_cas")
  );
  public usuariobus_log: Usuario = JSON.parse(
    localStorage.getItem("logindatos_bus")
  );
  public usuario_rec: Usuario = JSON.parse(
    localStorage.getItem("recordar_cas")
  );
  public token_gen = localStorage.getItem("token_cas");

  public cerrar_sesionbus(): void {
    localStorage.removeItem("token_bus");
    localStorage.removeItem("logindatos_bus");
    this.router.navigate(["/business"]);
    this.router.navigate(["/business"]).then(() => {
      window.location.reload();
    });
  }

  closeMenu() {
    var boxOne = document.getElementById("global-side-nav");
    boxOne.classList.add("horizTranslateRev");
    boxOne.classList.remove("horizTranslate");
  }
  public cerrar_sesion(): void {
    localStorage.removeItem("token_cas");
    localStorage.removeItem("logindatos_cas");
    this.router.navigate(["/adminlogin"]);
  }

  public sesionadmin() {
    // console.log(localStorage.getItem("token_cas"));
    // console.log(localStorage.getItem("logindatos_cas"));
    if (localStorage.getItem("token_cas") !== null) {
      if (localStorage.getItem("logindatos_cas") !== null) {
        this.usuario_log = JSON.parse(localStorage.getItem("logindatos_cas"));
      } else {
        localStorage.removeItem("logindatos_cas");
        this.router.navigate(["/adminlogin"]);
      }
    } else {
      localStorage.removeItem("logindatos_cas");
      this.router.navigate(["/adminlogin"]);
    }
  }

  public sesionbusiness() {
    // console.log(localStorage.getItem("token_bus"));
    // console.log(localStorage.getItem("logindatos_bus"));
    if (localStorage.getItem("token_bus") !== null) {
      if (localStorage.getItem("logindatos_bus") !== null) {
        this.usuario_log = JSON.parse(localStorage.getItem("logindatos_bus"));
      } else {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/business"]).then(() => {
          window.location.reload();
        });
      }
    } else {
      localStorage.removeItem("logindatos_bus");
      this.router.navigate(["/business"]).then(() => {
        window.location.reload();
      });
    }
  }

  async listado_ciudades() {
    this.ciudad_lst = new Array<Categoria>();

    let request = {};

    await axios
      .post(this.apiUrl + "list_ciudades", request, {
        headers: this.headers,
      })
      .then((response) => {

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                0,
                "",
                categoria_lst_aux[element].descripcion,
                true,
                0,
                0,
                null,
                null,
                null,
                null,
               "",
                ""
              );
              // console.log("num " + element);
              this.ciudad_lst.push(categoria_aux);

            }

          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async listado_categoria($id:number) {
    this.envase_lst = new Array<Categoria>();
    this.unidadmedida_lst = new Array<Categoria>();

    let request = {
      id: $id,
      superior: 0,
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "list_categoria", request, {
        headers: this.headers,
      })
      .then((response) => {
        // console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              // console.log("num " + element);
              if($id == 1){
                this.envase_lst.push(categoria_aux);
              }else if($id == 2){
                this.unidadmedida_lst.push(categoria_aux);
              }
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  refresh(): void {
    window.location.reload();
}

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            // console.log(blob);
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
}
