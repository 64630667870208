import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "business/createduser",
  templateUrl: "./createduser.component.html",
})
export class CreatedUserComponent implements OnInit {
  public title: string;
  // public rute: string;
  public codigo_usu: string;
  public codigo_aux: string;
  public tipo: string;
  public id_usu: string;
  public passwordrep_usu: string;
  public password_usu: string;
  public usuario_usu: string;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public actroute: ActivatedRoute,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Crear usuario";
    this.id_usu = atob(this.actroute.snapshot.paramMap.get("id"));
    this.codigo_aux = atob(
      this.actroute.snapshot.paramMap.get("codigoconfirmacion")
    );

    this.tipo = atob(this.actroute.snapshot.paramMap.get("tipo"));
    this.codigo_usu = "";
    // this.rute =
    //   "business/createduser/" +
    //   this.actroute.snapshot.paramMap.get("codigoconfirmacion") +
    //   "/" +
    //   this.actroute.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    console.log("createduser.component cargado!");
    this.validate_create_user();
  }
  public _banval: boolean = true;
  public _mensajeval: string = "";
  public validar() {
    if (this.usuario_usu.trim() == "") {
      this._banval = false;
      this._mensajeval = "Ingrese su usuario.";
    } else if (this.password_usu.trim() == "") {
      this._banval = false;
      this._mensajeval = "Ingrese contraseña.";
    } else if (this.passwordrep_usu.trim() == "") {
      this._banval = false;
      this._mensajeval = "Repita la contraseña.";
    } else if (this.passwordrep_usu.trim() != this.password_usu.trim()) {
      this._banval = false;
      this._mensajeval = "Las contraseñas no coinciden.";
    } else if (this.codigo_usu.trim() == "") {
      this._banval = false;
      this._mensajeval = "Ingrese código de confirmación (revise su email).";
    } else {
      this._banval = true;
    }
  }

  async validate_create_user() {
    let request = {
      id: this.id_usu,
      codigo: this.codigo_aux,
    };

    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "validate_created_user", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de Usuario", response.data.mensaje, "success").then(
            () => {
              this.codigo_usu = "";
              this.codigo_aux = "";
              this.password_usu = "";
              this.passwordrep_usu = "";
              this.usuario_usu = "";
            }
          );
        } else {
          swal("Registro de Usuario", response.data.mensaje, "info").then(
            () => {
              this.tipo != "tienda"
                ? window.open(this.constants.index + "#/business", "_blank")
                : (location.href = this.constants.indextienda);
            }
          );
        }
      })
      .catch(function (error) {
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async create_user() {
    this.spinner.show();
    this.validar();
    if (this._banval) {
      let request = {
        usuario: this.usuario_usu,
        password: this.password_usu,
        repitepass: this.passwordrep_usu,
        id: this.id_usu,
        codigo: this.codigo_usu,
        tipo: this.tipo == "tienda" ? 1 : 0,
      };

      await axios
        .post(this.constants.apiUrl + "created_user", request, {
          headers: this.constants.headers,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.estado) {
            this.spinner.hide();
            swal("Registro de Usuario", response.data.mensaje, "success").then(
              () => {
                this.tipo != "tienda"
                  ? window.open(this.constants.index + "#/business", "_blank")
                  : (location.href = this.constants.indextienda);
              }
            );
          } else {
            this.spinner.hide();
            swal("Registro de Usuario", response.data.mensaje, "info");
          }
        })
        .catch(function (error) {
          this.spinner.hide();
          swal("Ha ocurrido un error al registrar!", error.message, "error");
          console.log(error);
        });
    } else {
      this.spinner.hide();
      swal("Registro de Usuario!", this._mensajeval, "info");
    }
  }
}
