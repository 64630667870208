import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbDateStruct, NgbTypeaheadConfig } from "@ng-bootstrap/ng-bootstrap";
import { Producto } from "src/app/models/producto";
import { Beneficio, beneficioProducto } from "src/app/models/beneficio";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { DatePipe } from "@angular/common";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { isNumber } from "util";
import { Canje } from "src/app/models/canje";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "benefitbus",
  templateUrl: "./benefitbus.component.html",
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class BenefitBusComponent implements OnInit {
  model: NgbDateStruct;
  public title: string;
  public rute: string;
  public beneficios_lst: Array<Beneficio>;
  public beneficios_fil: Array<Beneficio>;
  public beneficio: Beneficio;
  public beneficio_reg: Beneficio;
  public beneficio_reg2: Beneficio;
  public beneficio_reg3: Beneficio;
  public _banregistro: boolean = false;
  public _bancanje:boolean = false
  public searchinput: string = "";
  public tipo: number = 0;
  public productos_lst: Array<Producto>;
  public productosreg_lst: Array<Producto>;
  public productos2_lst: Array<Producto>;
  public productos3_lst: Array<Producto>;
  public productosreg2_lst: Array<Producto>;
  public beneficioProducto: Array<beneficioProducto>;
  public producto: Producto;
  public errsmall: boolean = false;
  public errlarge: boolean = false;
  hoveredDate: NgbDate;
  public small_log: string;
  public large_log: string;
  fromDate: NgbDate;
  toDate: NgbDate;

  public ban_descglobal: boolean = false;
  //Validar beneficios
  public _ben01: boolean = false;
  public _ben02: boolean = false;
  public _ben03: boolean = false;
  public _ben04: boolean = false;
  public _ben05: boolean = false;
  public _ben06: boolean = false;
  public _ver: boolean = true;
  public _disabled: any = "disabled";
  public ben_precioactual: any = "0.00";
  public ben_idProducto: number = 0;
  public tipobeneficio: string = "";
  public canje_lst:Array<Canje>
  fechafin:any;
  fechainicio:any;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public datepipe: DatePipe,
    public formatterdt: NgbDateParserFormatter,
  ) {
    this.title = "Beneficios";
    this.rute = "/business/productbus";
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
    // this.constants.sesionaprov();
    this.constants.sesionbusiness();
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );
    console.log("USUARIO BUS " + JSON.stringify(this.constants.usuariobus_log));
    this.beneficios_lst = new Array<Beneficio>();
    this.beneficios_fil = new Array<Beneficio>();
    this.productos_lst = new Array<Producto>();
    this.productosreg_lst = new Array<Producto>();
    this.productos3_lst = new Array<Producto>();
    this.canje_lst = new Array<Canje>()
    this.beneficioProducto = new Array<beneficioProducto>();
    this.beneficio = new Beneficio(
      0,
      "",
      "",
      0,
      0,
      0,
      parseFloat("0").toFixed(2),
      parseFloat("0").toFixed(2),
      0,
      0,
      0,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      null,
      null
    );
    this.iniciar_producto_model();
  }
  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();
    this.listado_beneficios();
    this.listado_productos();
    this.fechainicio = this.fromDate.year + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + this.fromDate.day.toString().padStart(2, "0")
    this.fechafin =  this.toDate.year + "-" + this.toDate.month.toString().padStart(2, "0") + "-" + this.toDate.day.toString().padStart(2, "0")
  }

  search_beneficio($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.beneficios_lst = new Array<Beneficio>();

    if (this.tipo == 0) {
      this.beneficios_lst = this.beneficios_fil.filter(
        (beneficio: Beneficio) =>
          beneficio.ben_descripcion.toLocaleLowerCase().indexOf($txt) !== -1
      );
    } else if (this.tipo == 1) {
      this.beneficios_lst = this.beneficios_fil.filter(
        (beneficio: Beneficio) =>
          beneficio.ben_codigo.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }else if (this.tipo == 2) {
      this.beneficios_lst = this.beneficios_fil.filter(
        (beneficio: Beneficio) =>
          beneficio.ben_tipodesc.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }
  }

  public seleccionar_producto2($id: number) {
    this.beneficio_reg.ben_precioactual = parseFloat("0.00").toFixed(2);
    if ($id !== null) {
      this.productos_lst.forEach((item, index) => {
        if (item.prod_id == $id) {
          this.beneficio_reg.ben_precioactual = parseFloat(
            item.prod_precio
          ).toFixed(2);
        }
      });
    } else {
      this.beneficio_reg.ben_precioactual = parseFloat("0").toFixed(2);
    }
  }
  public seleccionar_producto($id: number, tipo: boolean) {
    if (tipo) {
      this.beneficio_reg2.ben_precioactual = parseFloat("0.00").toFixed(2);
      if ($id !== null) {
        this.productos_lst.forEach((item, index) => {
          if (item.prod_id == $id) {
            this.beneficio_reg2.ben_precioactual = parseFloat(
              item.prod_precio
            ).toFixed(2);
          }
        });
      } else {
        this.beneficio_reg2.ben_precioactual = parseFloat("0").toFixed(2);
      }
    } else {
      this.beneficio_reg3.ben_precioactual = parseFloat("0.00").toFixed(2);
      if ($id !== null) {
        this.productosreg_lst.forEach((item, index) => {
          if (item.prod_id == $id) {
            this.beneficio_reg3.ben_precioactual = parseFloat(
              item.prod_precio
            ).toFixed(2);
          }
        });
      } else {
        this.beneficio_reg3.ben_precioactual = parseFloat("0").toFixed(2);
      }
    }
  }

  async listado_productos() {
    this.spinner.show();
    this.productos_lst = new Array<Producto>();
    let request = {
      id: this.constants.usuariobus_log.us_idProveedor,
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_productos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("producto \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          this.productos_lst.push(
            new Producto(
              0,
              "",
              "Seleccione...",
              "",
              parseFloat("0.0").toFixed(2),
              parseFloat("0.0").toFixed(2),
              parseFloat("0.0").toFixed(2),
              "",
              0,
              "",
              0,
              "",
              false,
              null,
              null,
              null,
              null,
              null,
              0,
              "",
              0,
              0
            )
          );
          if (response.data.data != null) {
            let productos_lst_aux = response.data.data;
            let prodcuto_aux: Producto;
            for (let element in productos_lst_aux) {
              // console.log("num " + element);
              prodcuto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].imagen,
                parseFloat(productos_lst_aux[element].cantidad).toFixed(2),
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                productos_lst_aux[element].adminactualiza,
                null,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                0
              );
              // console.log("num " + element);
              this.productos_lst.push(prodcuto_aux);
            }
            this.productosreg_lst = this.productos_lst;
            this.productos3_lst = this.productos_lst;
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        } else {
          swal("Registro de productos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  public lista_beneficios_refresh() {
    this.searchinput = "";
    this.search_beneficio("");
  }

  public iniciar_producto_model() {
    this.beneficio_reg = new Beneficio(
      0,
      "",
      "",
      0,
      0,
      0,
      parseFloat("0").toFixed(2),
      parseFloat("0").toFixed(2),
      0,
      0,
      0,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      null,
      null
    );
    this.beneficio_reg2 = new Beneficio(
      0,
      "",
      "",
      0,
      0,
      0,
      parseFloat("0").toFixed(2),
      parseFloat("0").toFixed(2),
      0,
      0,
      0,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      null,
      null
    );
    this.beneficio_reg3 = new Beneficio(
      0,
      "",
      "",
      0,
      0,
      0,
      parseFloat("0").toFixed(2),
      parseFloat("0").toFixed(2),
      0,
      0,
      0,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      null,
      null
    );
  }

  public controles_enabled(_ban: boolean) {
    // document.getElementById("tipo").disabled = _ban;
  }

  public nuevo_registro() {
    this.controles_enabled(false);
    this._ver = true;
    this.productos2_lst = new Array<Producto>();
    this.productosreg2_lst = new Array<Producto>();

    this._banregistro = true;
    this.errsmall = false;
    this.errlarge = false;
    this.small_log = "";
    this.large_log = "";
    this.iniciar_producto_model();
  }

  public limpiar_listados_benef() {
    this.beneficios_lst = new Array<Beneficio>();
    this.beneficios_fil = new Array<Beneficio>();
  }

  async listado_beneficios() {
    this.spinner.show();
    this.beneficios_lst = new Array<Beneficio>();
    this.beneficios_fil = new Array<Beneficio>();

    let request = {
      id: this.constants.usuariobus_log.us_idProveedor,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_beneficios", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          let beneficios_lst_aux = response.data.data;
          let beneficio_aux: Beneficio;
          if (beneficios_lst_aux != null) {
            for (let element in beneficios_lst_aux) {
              console.log("num " + element);
              beneficio_aux = new Beneficio(
                beneficios_lst_aux[element].id,
                beneficios_lst_aux[element].codigo,
                beneficios_lst_aux[element].descripcion,
                beneficios_lst_aux[element].cantidadcompra,
                beneficios_lst_aux[element].cantidadbeneficio,
                beneficios_lst_aux[element].descuento,
                beneficios_lst_aux[element].preciocompra,
                beneficios_lst_aux[element].preciocompra,
                beneficios_lst_aux[element].idProducto,
                beneficios_lst_aux[element].idProveedor,
                beneficios_lst_aux[element].tipo,
                beneficios_lst_aux[element].vigencia == 1 ? true : false,
                beneficios_lst_aux[element].fechainicio,
                beneficios_lst_aux[element].fechafin,
                beneficios_lst_aux[element].fecharegistro,
                beneficios_lst_aux[element].adminregistro,
                beneficios_lst_aux[element].fechaactualiza,
                beneficios_lst_aux[element].adminactualiza,
                beneficios_lst_aux[element].producto,
                beneficios_lst_aux[element].tipodesc,
                beneficios_lst_aux[element].imagen_large,
                beneficios_lst_aux[element].imagen_small,
                this.constants.apiUrl +
                  "storage?ruta=" +
                  beneficios_lst_aux[element].imagen_small,
                this.constants.apiUrl +
                  "storage?ruta=" +
                  beneficios_lst_aux[element].imagen_large
              );
              this.beneficios_fil.push(beneficio_aux);
            }
          }
          this.beneficios_lst = this.beneficios_fil;
        } else {
          swal("Registro de beneficios", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_beneficio_productos(id: any) {
    this.spinner.show();
    this.productos2_lst = new Array<Producto>();
    this.productosreg2_lst = new Array<Producto>();

    let request = {
      idbenef: id,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_productos_beneficio", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          let lst_aux = response.data.data;
          let producto_aux: Producto;
          if (lst_aux != null) {
            for (let element in lst_aux) {
              console.log("num " + element);
              producto_aux = new Producto(
                lst_aux[element].id,
                lst_aux[element].codigo,
                lst_aux[element].producto,
                null,
                0,
                lst_aux[element].cantidad,
                0,
                null,
                0,
                "",
                0,
                "",
                false,
                null,
                0,
                null,
                0,
                null,
                0,
                lst_aux[element].categoria,
                0,
                0
              );
              if (
                !lst_aux[element].dona ||
                lst_aux[element].dona == "0" ||
                lst_aux[element].dona == 0
              )
                this.productos2_lst.push(producto_aux);

              if (
                lst_aux[element].dona ||
                lst_aux[element].dona == "1" ||
                lst_aux[element].dona == 1
              )
                this.productosreg2_lst.push(producto_aux);
            }
          }
          this.beneficios_lst = this.beneficios_fil;
        } else {
          swal("Registro de beneficios", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  public cancelar_registro() {
    this._banregistro = false;
  }

  async deshabilitar_habilitar($beneficio: Beneficio, $estado: boolean) {
    this.spinner.show();
    this.beneficio_reg = $beneficio;
    let request = { id: this.beneficio_reg.ben_id, vigencia: $estado };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_beneficio", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de beneficio", response.data.mensaje, "success").then(
            () => {
              this.listado_beneficios();
              this._banregistro = false;
            }
          );
        } else {
          swal("Registro de beneficio", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  public editar($beneficio: Beneficio) {
    this.productos2_lst = new Array<Producto>();
    this.productosreg2_lst = new Array<Producto>();
    this._ver = true;
    this.controles_enabled(true);
    this.cambiar_beneficio($beneficio.ben_tipo);
    this.beneficio_reg = $beneficio;
    let fechainicio = this.beneficio_reg.ben_fechainicio.toString().split("-")
    let dateInicio  = JSON.parse(JSON.stringify({ day: parseInt(fechainicio[2]), month: parseInt(fechainicio[1]), year: parseInt(fechainicio[0])}))
    this.fromDate = dateInicio

    let fechafin = this.beneficio_reg.ben_fechafin.toString().split("-")
    let dateFin = JSON.parse(JSON.stringify({ day: parseInt(fechafin[2]), month: parseInt(fechafin[1]), year: parseInt(fechafin[0])}))
    this.toDate =  dateFin

    if (
      $beneficio.ben_tipo == 9 ||
      $beneficio.ben_tipo == 22 ||
      $beneficio.ben_tipo == 29 || $beneficio.ben_tipo == 8
    ) {
      console.log('prueba de edicón');
      this.seleccionar_producto2(this.beneficio_reg.ben_idProducto);
    }
    // console.log(JSON.stringify(this.productos_lst));
    // console.log(this.beneficio_reg);
    if (
      $beneficio.ben_tipo == 22 ||
      $beneficio.ben_tipo == 7
    ) {
      console.log("productos");
    this.listado_beneficio_productos(this.beneficio_reg.ben_id);
    }
    this.small_log = this.beneficio_reg.ben_imagen_small;
    this.large_log = this.beneficio_reg.ben_imagen_large;
    if (this.small_log) {
      this.errsmall = true;
    }
    if (this.large_log) {
      this.errlarge = true;
    }
    this._banregistro = true;
  }

  async guardar_beneficio() {
    this.spinner.show();
    let large_img: any = null;
    let small_img: any = null;
    // console.log(this.beneficio_reg.ben_id.toString());
    // console.log(this.beneficio_reg.ben_imagen_large);
    // console.log(this.beneficio_reg.ben_imagen_small);

    if (this.errlarge) {
      if (this.large_log == "" && this.beneficio_reg.ben_imagen_large != null) {
        large_img = this.beneficio_reg.ben_imagen_large.files[0];
      } else {
        large_img = null;
      }
    }

    if (this.errsmall) {
      if (this.small_log == "" && this.beneficio_reg.ben_imagen_small != null) {
        small_img = this.beneficio_reg.ben_imagen_small.files[0];
      } else {
        small_img = null;
      }
    }
    console.log(this.beneficio_reg.ben_tipo.toString());
    let request = new FormData();
    request.append("id", this.beneficio_reg.ben_id.toString());
    request.append("codigo", this.beneficio_reg.ben_codigo);
    request.append("descripcion", this.beneficio_reg.ben_descripcion || "");
    request.append(
      "cantidadcompra",
      this.beneficio_reg.ben_cantidadcompra || 0.0
    );
    request.append(
      "cantidadbeneficio",
      this.beneficio_reg.ben_cantidadbeneficio || 0.0
    );
    request.append("descuento", this.beneficio_reg.ben_descuento || 0.0);
    request.append("preciocompra", this.beneficio_reg.ben_preciocompra || 0.0);
    request.append(
      "idProveedor",
      this.constants.usuariobus_log.us_idProveedor.toString()
    );
    request.append(
      "idProducto",
      this.beneficio_reg.ben_idProducto.toString() || "null"
    );
    request.append("tipo", this.beneficio_reg.ben_tipo.toString());
    request.append(
      "fechainicio",
      this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day
    ),
      request.append(
        "fechafin",
        this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day
      );
    request.append("small_img", small_img);
    request.append("large_img", large_img);
    this.beneficioProducto = new Array<beneficioProducto>();
    let benefprod: beneficioProducto;

    if (this.productos2_lst.length > 0) {
      for (let element in this.productos2_lst) {
        benefprod = new beneficioProducto(
          0,
          this.productos2_lst[element].prod_precio,
          this.productos2_lst[element].prod_id,
          0,
          false,
          true,
          null,
          null,
          null,
          null,
          "",
          "",
          ""
        );
        this.beneficioProducto.push(benefprod);
      }
    }
    if (this.productosreg2_lst.length > 0) {
      for (let element in this.productosreg2_lst) {
        benefprod = new beneficioProducto(
          0,
          this.productosreg2_lst[element].prod_precio,
          this.productosreg2_lst[element].prod_id,
          0,
          true,
          true,
          null,
          null,
          null,
          null,
          "",
          "",
          ""
        );
        this.beneficioProducto.push(benefprod);
      }
    }
    console.log(JSON.stringify(this.beneficioProducto));
    request.append(
      "beneficio_producto",
      JSON.stringify(this.beneficioProducto)
    );
    this.spinner.hide();
    await axios
      .post(this.constants.apiUrl + "registro_beneficio", request, {
        headers: this.constants.headers_formimage,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de beneficio", response.data.mensaje, "success").then(
            () => {
              this.listado_beneficios();
              this._banregistro = false;
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de beneficio", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    this.spinner.hide();
  }

  //FECHAS
  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  limpiar_adj($id: string) {
    // console.log($file);
    if ($id === "large") {
      this.beneficio_reg.ben_imagen_large = null;
      if (
        this.beneficio_reg.ben_imagen_large === "" ||
        this.beneficio_reg.ben_imagen_large === null ||
        this.beneficio_reg.ben_imagen_large === undefined
      ) {
        this.errlarge = false;
      } else {
        this.errlarge = true;
      }
    } else if ($id === "small") {
      this.beneficio_reg.ben_imagen_small = null;
      if (
        this.beneficio_reg.ben_imagen_small === "" ||
        this.beneficio_reg.ben_imagen_small === null ||
        this.beneficio_reg.ben_imagen_small === undefined
      ) {
        this.errsmall = false;
      } else {
        this.errsmall = true;
      }
    }
  }

  previewimage($file: any, $id: string) {
    if (localStorage.getItem("token_bus") !== null) {
      // let files = $file.files;

      console.log("ID " + $id);
      if ($id == "large") {
        // this.beneficio_reg.ben_imagen_large = files.files;
        this.beneficio_reg.ben_imagen_large = document.getElementById($id);
        this.errlarge = true;
        this.large_log = "";
      } else if ($id == "small") {
        // this.beneficio_reg.ben_imagen_small = files.files;
        this.beneficio_reg.ben_imagen_small = document.getElementById($id);
        this.errsmall = true;
        this.small_log = "";
      }
      console.log(this.beneficio_reg.ben_imagen_small);
      console.log(this.beneficio_reg.ben_imagen_large);
      // if (files.length === 0) return;
      // let mimeType = files[0].type;
      // if (mimeType.match(/image\/*/) == null) {
      //   // this.message = "Only images are supported.";
      //   return;
      // }
      // let reader = new FileReader();
      // reader.readAsDataURL(files[0]);
      // reader.onload = _event => {
      //   if ($id == "large") {
      //     console.log("large");
      //     this.beneficio_reg.ben_imagen_large = reader.result;
      //     this.errlarge = true;
      //   } else if ($id == "small") {
      //     console.log("small");
      //     this.beneficio_reg.ben_imagen_small = reader.result;
      //     this.errsmall = true;
      //   }
      //   // this.empresa_reg._logofile = document.getElementById($id);
      // };
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/business/dashboardprov"]);
      });
    }
  }

  cambiar_beneficio(codigo: any) {
    this.productos2_lst = new Array<Producto>();
    this.productosreg2_lst = new Array<Producto>();
    this.ben_idProducto = 0;
    this.ben_precioactual = "0.00";
    this.errlarge = false;
    this.errsmall = false;
    this.beneficio_reg = new Beneficio(
      0,
      "",
      "",
      0,
      0,
      0,
      parseFloat("0").toFixed(2),
      parseFloat("0").toFixed(2),
      0,
      0,
      codigo,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      null,
      null,
      null,
      null
    );
    this._ben01 = false;
    this._ben02 = false;
    this._ben03 = false;
    this._ben04 = false;
    this._ben05 = false;
    this._ben06 = false;
    this.ban_descglobal = false;
    switch (parseInt(codigo)) {
      case 9:
        this._ben01 = true;
        this.tipobeneficio = "BONIFICACIÓN";
        break;
      case 21:
        this._ben02 = true;
        this.ban_descglobal = true;
        break;
      case 8:
        this._ben03 = true;
        this.tipobeneficio = "PROMOCIÓN";
        break;
      case 7:
        this.tipobeneficio = "REGALO";
        this._ben04 = true;
        break;
      case 22:
        this.tipobeneficio = "REGALO";
        this._ben05 = true;
        break;
      case 29:
        this._ben06 = true;
        break;
    }
    console.log(this._ben01);
  }

  agregar_producto(tipo: boolean) {
    let $modelproducto: Producto;
    $modelproducto = new Producto(
      0,
      "",
      "Seleccione...",
      "",
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      parseFloat("0.0").toFixed(2),
      "",
      0,
      "",
      0,
      "",
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      "",
      0,
      0
    );
    let _ban: Boolean = true;
    $modelproducto.prod_id = 0;
    if (tipo) {
      $modelproducto.prod_id = this.beneficio_reg2.ben_idProducto;
      $modelproducto.prod_precio = this.beneficio_reg2.ben_cantidadcompra;
    } else {
      $modelproducto.prod_id = this.beneficio_reg3.ben_idProducto;
      $modelproducto.prod_precio = this.beneficio_reg3.ben_cantidadbeneficio;
    }
    console.log($modelproducto);
    console.log(this.beneficio_reg.ben_cantidadcompra);
    this.productos_lst.forEach((item, index) => {
      // console.log("PRODUCTO ITEM" + JSON.stringify(item))
      if (item.prod_id == $modelproducto.prod_id) {
        $modelproducto.prod_categoria = item.prod_categoria;
        $modelproducto.prod_descripcion = item.prod_descripcion;
        $modelproducto.prod_codigo = item.prod_codigo;
      }
    });

    if ($modelproducto.prod_id === 0) {
      _ban = false;
      swal("Seleccione un producto.", {
        icon: "info",
        title: "Resgitro de beneficios",
      });
    } else {
      if (!isNumber(parseFloat($modelproducto.prod_precio.toString()))) {
        _ban = false;
        swal("La cantidad debe ser númerica.", {
          icon: "info",
          title: "Registro de beneficios",
        });
      } else {
        if (parseFloat($modelproducto.prod_precio.toString()) <= 0) {
          _ban = false;
          swal("La cantidad debe ser mayor a 0.", {
            icon: "info",
            title: "Registro de beneficios",
          });
        }
      }
    }
    if (_ban) {
      if (tipo) {
        if (this.productos2_lst.length === 0) {
          this.productos2_lst.push($modelproducto);
        } else {
          this.productos2_lst.forEach((item, index) => {
            // console.log("PRODUCTO ITEM" + JSON.stringify(item))
            if (item.prod_id === $modelproducto.prod_id) {
              _ban = false;
              swal("Producto ya ha sido agregado.", {
                icon: "info",
                title: "Productos",
              });
            }
          });
          if (_ban) {
            this.productos2_lst.push($modelproducto);
          }
        }
      } else {
        if (this.productosreg2_lst.length === 0) {
          this.productosreg2_lst.push($modelproducto);
        } else {
          this.productosreg2_lst.forEach((item, index) => {
            // console.log("PRODUCTO ITEM" + JSON.stringify(item))
            if (item.prod_id === $modelproducto.prod_id) {
              _ban = false;
              swal("Producto ya ha sido agregado.", {
                icon: "info",
                title: "Productos",
              });
            }
          });
          if (_ban) {
            this.productosreg2_lst.push($modelproducto);
          }
        }
      }
    }
  }

  eliminar_producto($modelproducto: Producto, tipo: boolean) {
    swal({
      title: "Productos",
      text: "¿Desea quitar producto de la lista? .",
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then(async (willDelete) => {
      if (willDelete) {
        let pos_l: number = -1;
        if (tipo) {
          this.productos2_lst.forEach((item, index) => {
            // console.log(" ITEM " + index + " " + item._idproductoregalo )
            if (item === $modelproducto) {
              pos_l = index;
            }
          });

          if (pos_l !== -1) {
            this.productos2_lst.splice(pos_l, 1);
          }
        } else {
          this.productosreg2_lst.forEach((item, index) => {
            // console.log(" ITEM " + index + " " + item._idproductoregalo )
            if (item === $modelproducto) {
              pos_l = index;
            }
          });

          if (pos_l !== -1) {
            this.productosreg2_lst.splice(pos_l, 1);
          }
        }
      }
    });
  }

  async listar(){
    this.spinner.show();
    this.canje_lst = new Array<Canje>();
    let request = {
      id: this.constants.usuariobus_log.us_idProveedor,
      tipo: 1,
      fechainicio:
        this.fromDate.year.toString() +
        "-" +
        this.fromDate.month.toString().padStart(2, "00") +
        "-" +
        this.fromDate.day.toString().padStart(2, "00"),
        fechafin:
        this.toDate.year.toString() +
        "-" +
        this.toDate.month.toString().padStart(2, "00") +
        "-" +
        this.toDate.day.toString().padStart(2, "00"),
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "canje-list", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("ListaCanje \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          let canje_aux = response.data.data;
          for (let element in canje_aux) {
            canje_aux[element].imagen_small =
                this.constants.apiUrl +
                  "storage?ruta=" +
                  canje_aux[element].imagen_small
                  canje_aux[element].imagen_large =
                this.constants.apiUrl +
                  "storage?ruta=" +
                  canje_aux[element].imagen_large
                  canje_aux[element].fechacanje = (canje_aux[element].fechacanje  == null ? '' : canje_aux[element].fechacanje)
            this.canje_lst.push(canje_aux[element]);
          }

          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        console.log(JSON.stringify(this.canje_lst))
        this.spinner.hide();
      })
      .catch(function (error) {
        this.spinner.hide();
        console.log(error);
      });
  }

  canjear(){
    this._bancanje = true
    this._banregistro = false
    this.toDate = this.calendar.getToday();
      this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -10);
      this.fechainicio = this.fromDate.year + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + this.fromDate.day.toString().padStart(2, "0")
      this.fechafin =  this.toDate.year + "-" + this.toDate.month.toString().padStart(2, "0") + "-" + this.toDate.day.toString().padStart(2, "0")
    this.listar()
  }

  cancelarcanje(){
    this._bancanje = false
    this._banregistro = false
    this.toDate = null;
      this.fromDate = null;
      this.listado_beneficios()
  }

  async regcanje($canje:Canje){
console.log(JSON.stringify($canje));

    this.spinner.show();

    let request = {
      id: $canje.id
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "canje-efect", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log("Canje \n" + JSON.stringify(response.data));
        this.spinner.hide();
        if (response.data.estado) {
          swal("Registro de Código", response.data.mensaje, "success");
          this.canjear();
        }else{
          swal("Registro de Código", response.data.mensaje, "success");
        }

      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });

  }
}
