import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from "rxjs";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { debounceTime } from "rxjs/operators";
import { Organizacion } from "src/app/models/organizacion";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ConstantsService } from "src/app/constants.service";
import { Categoria } from 'src/app/models/categoria';
const swal: SweetAlert = _swal as any;
@Component({
  selector: "footer_ad",
  templateUrl: "./footer_ad.component.html",
})
export class FooterADComponent {
  private _name = "";
  public titulo: string;
  public ruta: string;
  public anio: string;
  public state_back_top: boolean = false;

  public organizacion_reg: Organizacion;
  public closeResult = "";
  public _enviar: boolean = false;
  successMessage = "";
  public typealert: string = "";
  private _success = new Subject<string>();
  public ciudad_lst:Array<Categoria>
  public _cv: any;
  public errcv: boolean = false;
  public cv_log: string;
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    private modalService: NgbModal
  ) {
    let today = new Date();
    let year = today.getFullYear();
    this.anio = year.toString();
  }

  ngOnInit() {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(5000))
      .subscribe(() => (this.successMessage = ""));
      this.ciudad_lst = new Array<Categoria>()
      this.organizacion_reg = new Organizacion(
        0,
        "",
        "",
        "",
        "",
        0,
        "",
        0,
        "Perú",
        "",
        "",
        false,
        false,
        false,
        false,
        "",
        "",
        false,
        null,
        null,
        null,
        null
      );
    this.constants.listado_ciudades();
  }



  open(content) {
    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title",  windowClass : "modal-dialog-cb"  })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async Contactanos(captchaResponse: string) {
    // var name = $scope.negocio_prov;
    // console.log("name is " + name);
    this.typealert = "warning";
    if (this.organizacion_reg.org_empresa.trim() == "") {
      this._success.next("Ingrese nombre de negocio");
      return false;
    }

    if (this.organizacion_reg.org_representante.trim() == "") {
      this._success.next("Ingrese nombre de representante");
      return false;
    }

    if (this.organizacion_reg.org_email.trim() == "") {
      this._success.next("Ingrese email");
      return false;
    }

    if (this.organizacion_reg.org_mensaje.trim() == "") {
      this._success.next("Ingrese mensaje");
      return false;
    }
    this._enviar = true;
    let request = {
      empresa: this.organizacion_reg.org_empresa,
      representante: this.organizacion_reg.org_representante,
      telefono: this.organizacion_reg.org_telefono,
      email: this.organizacion_reg.org_email,
      pais: this.organizacion_reg.org_pais,
      ciudad: this.organizacion_reg.org_ciudad,
      mensaje: this.organizacion_reg.org_mensaje,
      tokenrecaptcha: captchaResponse,
    };

    console.log("CONTACTANOS: " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "contactanos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "success").then(() => {
            this.modalService.dismissAll();
          });
        } else {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }

  previewcv($file: any, $id: string) {
    this._cv = document.getElementById($id);
    console.log(this._cv);

    this.errcv = true;
    this.cv_log = "";
  }

  limpiar_adj($id: string) {
    console.log($id);
    this._cv = null;
    if (this._cv === "" || this._cv === null || this._cv === undefined) {
      this.errcv = false;
    } else {
      this.errcv = true;
    }
  }

  async Laboral(captchaResponse: string) {
    // var name = $scope.negocio_prov;
    // console.log("name is " + name);
    this.typealert = "warning";

    if (this.organizacion_reg.org_representante.trim() == "") {
      this._success.next("Ingrese nombres y apellidos");
      return false;
    }

    if (this.organizacion_reg.org_email.trim() == "") {
      this._success.next("Ingrese email");
      return false;
    }

    if (this.organizacion_reg.org_ciudad.trim() == "") {
      this._success.next("Ingrese ciudad");
      return false;
    }

    let cv_aux: any = null;
    if (this.errcv) {
      if (this.cv_log == "") {
        cv_aux = this._cv.files[0];
      } else {
        cv_aux = null;
      }
    } else {
      this._success.next("Cargue su CV");
      return false;
    }
    this._enviar = true;
    console.log(cv_aux);
    let request = new FormData();
    request.append(
      "nombres",
      this.organizacion_reg.org_representante.toString()
    );
    request.append("telefono", this.organizacion_reg.org_telefono.toString());
    request.append("email", this.organizacion_reg.org_email.toString());
    request.append("ciudad", this.organizacion_reg.org_ciudad.toString());
    request.append("archivo", cv_aux);

    console.log("OPORTUNIDAD LABORAL: " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "oport_laboral", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this._enviar = false;
          swal("Oportunidad Laboral", response.data.mensaje, "success").then(
            () => {
              this.organizacion_reg = new Organizacion(
                0,
                "",
                "",
                "",
                "",
                0,
                "",
                0,
                "Perú",
                "",
                "",
                false,
                false,
                false,
                false,
                "",
                "",
                false,
                null,
                null,
                null,
                null
              );
              this.modalService.dismissAll();
            }
          );
        } else {
          this._enviar = false;
          swal("Oportunidad Laboral", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }
}
