import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "configbus",
  templateUrl: "./configbus.component.html",
})
export class ConfigBusComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipoorg: number = 0;
  public organizacion_reg: Organizacion;
  public claveactual_bs: string;
  public nuevaclave_bs: string;
  public repiteclave_bs: string;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Configuración de datos";
    this.rute = "business/datosprov";
    this.constants.sesionbusiness();
  }

  ngOnInit() {
    console.log("datosprov.component cargado!");
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );
    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
    this.tipoorg = this.constants.usuariobus_log.us_tipo;
    this.obtener_datos();
    this.constants.listado_ciudades();
  }

  async obtener_datos() {
    this.spinner.show();
    let request = {
      tipo: this.constants.usuariobus_log.us_tipo,
      id: this.constants.usuariobus_log.us_idProveedor,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_proveedor", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let organizacion_aux = response.data.data;
        if (response.data.estado) {
          this.organizacion_reg = new Organizacion(
            organizacion_aux.id,
            organizacion_aux.empresa,
            organizacion_aux.representante,
            organizacion_aux.email,
            organizacion_aux.telefono,
            organizacion_aux.negocio,
            organizacion_aux.otrosector,
            organizacion_aux.sector,
            organizacion_aux.pais.charAt(0).toUpperCase() +  organizacion_aux.pais.slice(1),
            organizacion_aux.ciudad.charAt(0).toUpperCase() +  organizacion_aux.ciudad.slice(1),
            organizacion_aux.codigopostal,
            organizacion_aux.proveedor,
            organizacion_aux.auspiciador,
            organizacion_aux.patrocinador,
            organizacion_aux.vigencia,
            organizacion_aux.mensaje,
            organizacion_aux.codigoconfirmacion,
            organizacion_aux.usuariocreado,
            organizacion_aux.fechavalidacion,
            organizacion_aux.adminvalidacion,
            organizacion_aux.fechaactualiza,
            organizacion_aux.adminactualiza
          );
        } else {
          swal("Datos de organización", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  cancelar_registro() {
    this.obtener_datos();
    this.claveactual_bs = "";
    this.repiteclave_bs = "";
    this.nuevaclave_bs = "";
  }

  async actualizar_clave() {
    swal("Cambiar Contraseña", "Operación por desarrollar...", "info");
  }

  async actualizar_organizacion() {
    this.spinner.show();
    this.tipoorg = this.constants.usuariobus_log.us_tipo;
    let request = {};
    if (this.tipoorg.toString() == "1") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        negocio: this.organizacion_reg.org_negocio,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        codigopostal: this.organizacion_reg.org_codigopostal,
        tipo: 1,
      };
    } else if (this.tipoorg.toString() == "2") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        sector: this.organizacion_reg.org_sector,
        otrosector: this.organizacion_reg.org_otrosector,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        codigopostal: this.organizacion_reg.org_codigopostal,
        tipo: 2,
      };
    } else if (this.tipoorg.toString() == "3") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        mensaje: this.organizacion_reg.org_mensaje,
        tipo: 3,
      };
    }
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Configuración de Datos", response.data.mensaje, "success").then(
            () => {
              this.obtener_datos();
            }
          );
        } else {
          swal("Configuración de Datos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}
