import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { ConstantsService } from "./constants.service";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CdkTableModule } from "@angular/cdk/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { RecaptchaModule } from "ng-recaptcha";
// import {
//   MatAutocompleteModule,
//   MatButtonModule,
//   MatButtonToggleModule,
//   MatCardModule,
//   MatCheckboxModule,
//   MatChipsModule,
//   MatDatepickerModule,
//   MatDialogModule,
//   MatDividerModule,
//   MatExpansionModule,
//   MatGridListModule,
//   MatIconModule,
//   MatInputModule,
//   MatListModule,
//   MatMenuModule,
//   MatNativeDateModule,
//   MatPaginatorModule,
//   MatProgressBarModule,
//   MatProgressSpinnerModule,
//   MatRadioModule,
//   MatRippleModule,
//   MatSelectModule,
//   MatSidenavModule,
//   MatSliderModule,
//   MatSlideToggleModule,
//   MatSnackBarModule,
//   MatSortModule,
//   MatStepperModule,
//   MatTableModule,
//   MatTabsModule,
//   MatToolbarModule,
//   MatTooltipModule,
// } from '@angular/material';
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/sites/template/header.component";
import { FooterComponent } from "./components/sites/template/footer.component";
import { FooterADComponent } from "./components/sites/template/footer_ad.component";
import { InformComponent } from "./components/sites/template/inform.component";
import { ServicesComponent } from "./components/sites/template/services.component";
import { VideoPublicComponent } from "./components/sites/template/videopublic.component";
import { HomeComponent } from "./components/sites/home.component";
import { HeaderADComponent } from "./components/sites/template/header_ad.component";
import { InformAdComponent } from "./components/sites/template/inform_ad.component";
import { ServicesADComponent } from "./components/sites/template/services_ad.component";
import { AdminHomeComponent } from "./components/sites/adminhome.component";
import { AdminLoginComponent } from "./components/sites/loginadmin.component";
import { CreatedUserComponent } from "./components/sites/createduser.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CambioPasswordComponent } from "./components/sites/cambiopassword.component";
import { CambioPasswordBusComponent } from "./components/sites/cambiopasswordbus.component";
import { DashboardAdminComponent } from "./components/sites/dashboardadmin.component";
import { CabeceraComponent } from "./components/sites/template/cabecera.component";
import { MenuComponent } from "./components/sites/template/menu.component";
import { TituloComponent } from "./components/sites/template/titulo.component";
import { providerCompanyComponent } from "./components/sites/providercompany.component";
import { DashboardBusComponent } from "./components/sites/dashboardbus.component";
import { CabeceraBusComponent } from "./components/sites/template/cabecerabus.component";
import { MenuBusComponent } from "./components/sites/template/menubus.component";
import { ProductBusComponent } from "./components/sites/productbus.component";
import { BenefitBusComponent } from "./components/sites/benefitbus.component";
import { TituloBusComponent } from "./components/sites/template/titulobus.component";
import { DatePipe } from "@angular/common";
import { ConfigBusComponent } from "./components/sites/configbus.component";
import { UserAdminComponent } from "./components/sites/useradmin.component";
import { NotificacionBusComponent } from "./components/sites/notificacionbus.component";
import { PublicidadBusComponent } from "./components/sites/publicidadbus.component";
import { PedidoBusComponent } from "./components/sites/pedidobus.component";

import { TiendaComponent } from "./components/sites/tienda.component";
import { CategoriaComponent } from "./components/sites/categoria.component";
import { ProductoComponent } from "./components/sites/producto.component";
import { EnvaseComponent } from "./components/sites/envase.component";
import { UnidadMedidaComponent } from "./components/sites/unidmedida.component";
import { GastosComponent } from "./components/sites/gastos.component";
import { NotificacionComponent } from "./components/sites/notificacion.component";
import { IngresoDatosComponent } from "./components/sites/ingresodatos.component";
import { PublicidadComponent } from "./components/sites/publicidad.component";
import { GoogleChartsModule } from 'angular-google-charts';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { VideoAdminComponent } from "./components/sites/videoadmin.component";
import { SololetrasDirective } from './sololetras.directive';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FooterADComponent,
    VideoAdminComponent,
    InformComponent,
    ServicesComponent,
    VideoPublicComponent,
    HomeComponent,
    HeaderADComponent,
    AdminHomeComponent,
    InformAdComponent,
    ServicesADComponent,
    AdminLoginComponent,
    CreatedUserComponent,
    DashboardAdminComponent,
    CabeceraComponent,
    MenuComponent,
    TituloComponent,
    CambioPasswordComponent,
    CambioPasswordBusComponent,
    providerCompanyComponent,
    DashboardBusComponent,
    CabeceraBusComponent,
    MenuBusComponent,
    ProductBusComponent,
    TituloBusComponent,
    BenefitBusComponent,
    ConfigBusComponent,
    UserAdminComponent,
    TiendaComponent,
    CategoriaComponent,
    ProductoComponent,
    EnvaseComponent,
    UnidadMedidaComponent,
    GastosComponent,
    NotificacionComponent,
    PublicidadComponent,
    NotificacionBusComponent,
    PublicidadBusComponent,
    PedidoBusComponent,
    IngresoDatosComponent,
    SololetrasDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CdkTableModule,
    NgxSpinnerModule,
    RecaptchaModule,
    GoogleChartsModule,
    ChartsModule,
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatDividerModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    BrowserAnimationsModule,

    GoogleChartsModule,
    FormsModule,
    // MatNativeDateModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  providers: [ConstantsService, DatePipe, ThemeService],
  entryComponents: [InformComponent, CambioPasswordComponent, CambioPasswordBusComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
// platformBrowserDynamic().bootstrapModule(AppModule);
