import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CambioPasswordBusComponent } from "../cambiopasswordbus.component";
import { Usuario } from "../../../models/usuario";
import { ConstantsService } from "../../../constants.service";
const MODALS = {
  changepass: CambioPasswordBusComponent,
};

@Component({
  selector: "cabecerabus",
  templateUrl: "./cabecerabus.component.html",
  styles: [
    `.modal-dialog{width: 350px !important;}`]
})
export class CabeceraBusComponent {
  @Input() usuario_cab: Usuario;
  withAutofocus = `<button type="button" ngbAutofocus class="btn btn-danger"
      (click)="modal.close('Ok click')">Ok</button>`;
  public _name = "";
  constructor(
    // public _router : ActivatedRoute,
    public _modalService: NgbModal,
    public router: Router,
    public constants: ConstantsService
  ) {
    console.log("US " + JSON.stringify(this.constants.usuariobus_log));
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );
    this.usuario_cab = this.constants.usuariobus_log;
  }

  public logoutbus(): void {
    localStorage.removeItem("token_bus");
    localStorage.removeItem("logindatos_bus");
    location.href = this.constants.index + "#/business";
  }

  open(name: string) {
    this._modalService.open(MODALS[name]);
  }
}
