export class Publicidad {
  constructor(
    public id: number,
    public codigo: string,
    public img_small: any,
    public img_large: any,
    public descripcion: string,
    public fechaInicio: any,
    public fechaFin: any,
    public vigencia: boolean,
    public idProveedor: number,
    public idTienda: number,
    public adminregistro: number,
    public fecharegistro: any,
    public adminactualiza: number,
    public fechaactualiza: any,
    public empresa: string,
    public url_large: string,
    public url_small: string
  ) {}
}
