export class Producto {
  constructor(
    public prod_id: number,
    public prod_codigo: string,
    public prod_descripcion: string,
    public prod_imagen: any,
    public prod_cantidad: any,
    public prod_precio: any,
    public prod_preciototal: any,
    public prod_idProveedor: string,
    public prod_idUnidad: number,
    public prod_unidad: string,
    public prod_idEnvase: number,
    public prod_envase: string,
    public prod_vigencia: boolean,
    public prod_fecharegistro: Date,
    public prod_adminregistro: number,
    public prod_fechaactualiza: Date,
    public prod_adminactualiza: number,
    public prod_imagen_url: any,
    public prod_idCategoria: number,
    public prod_categoria: string,
    public prod_superior: number,
    public prod_stock: any
  ) {}
}
