import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Personal } from "src/app/models/personal";
import { Usuario } from "src/app/models/usuario";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "useradmin",
  templateUrl: "./useradmin.component.html",
})
export class UserAdminComponent implements OnInit {
  public title: string;
  public rute: string;

  public _bancant: boolean = false;
  public personal_lst: Array<Personal>;
  public personal_reg: Personal;
  public usuario_lst: Array<Usuario>;
  public usuario_reg: Usuario;
  public _ver: boolean = true;
  public _verus: boolean = true;
  public reg_usuario: boolean = false;
  public reg_permiso: boolean = false;
  public showgenera: boolean = true;
  public _bancantus: boolean = false;
  public usuario_tienda: string = "";
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Personal";
    this.rute = "#/tda/usuarioperfil";
    this.constants.sesionadmin();
    this._ver = true;
    this.personal_lst = new Array<Personal>();
    this.usuario_lst = new Array<Usuario>();
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
  }

  ngOnInit() {
    console.log("datosprov.component cargado!");
    this.constants.usuario_log = JSON.parse(
      localStorage.getItem("logindatos_cas")
    );
    this.refrescar();
  }

  refrescar() {
    if (!this.reg_usuario) {
      this.listar_personal();
    } else {
      this.listar_usuarios();
    }
  }

  public async generateuser() {
    console.log("prueba");
    console.log(this.personal_reg);
    if (localStorage.getItem("token_cas") !== null) {
      let cant_apepaterno = this.personal_reg.per_apellidopaterno.length;
      let cant_letapepat = 6;
      let cant_letapemat = 1;
      if (cant_apepaterno < 6) {
        cant_letapepat = cant_apepaterno;
        cant_letapemat = cant_letapemat + (6 - cant_apepaterno);
      }
      this.usuario_reg.us_usuario = (
        this.personal_reg.per_nombre.substr(0, 1) +
        this.personal_reg.per_apellidopaterno.substr(0, cant_letapepat) +
        this.personal_reg.per_apellidomaterno.substr(0, cant_letapemat)
      ).toLocaleLowerCase();
      let estado: boolean = true;
      let cont_user = 1;
      let usuario_gen = this.usuario_reg.us_usuario;
      while (estado) {
        console.log(this.usuario_reg.us_usuario);
        let response = await axios.get(
          this.constants.apiUrl +
            "check_user?usuario=" +
            this.usuario_reg.us_usuario +
            "&token=" +
            localStorage.getItem("token_cas")
        );
        estado = response.data.estado;
        if (estado === false) break;
        let long_user = this.usuario_reg.us_usuario.length;
        console.log(long_user);
        if (long_user === 8) {
          usuario_gen = this.usuario_reg.us_usuario + cont_user.toString();
        } else {
          cont_user =
            parseInt(this.usuario_reg.us_usuario.substr(8, long_user - 8)) + 1;
          usuario_gen =
            this.usuario_reg.us_usuario.substr(0, 8) + cont_user.toString();
        }
        this.usuario_reg.us_usuario = usuario_gen;
      }
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        this.constants.cerrar_sesion();
      });
    }
  }

  async listar_personal() {
    this._bancant = false;
    this.spinner.show();
    this.constants.usuario_log = JSON.parse(
      localStorage.getItem("logindatos_cas")
    );
    let request = {
      idnegocio: this.constants.usuario_log.us_idTienda,
      token: this.constants.usuario_log.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_personal_admin");
    this.personal_lst = new Array<Personal>();
    await axios
      .post(this.constants.apiUrl + "lista_personal_admin", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let personal_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in personal_lst_aux) {
            let personal: Personal = new Personal(
              personal_lst_aux[element].id,
              personal_lst_aux[element].nombre,
              personal_lst_aux[element].apellidopaterno,
              personal_lst_aux[element].apellidomaterno,
              personal_lst_aux[element].email,
              personal_lst_aux[element].celular,
              personal_lst_aux[element].tienda == 1 ? true : false,
              personal_lst_aux[element].idempresa,
              personal_lst_aux[element].vigencia == 1 ? true : false,
              null,
              null,
              null,
              null
            );
            this.personal_lst.push(personal);
          }
          if (this.personal_lst.length > 0) this._bancant = true;
          this.spinner.hide();
        }
        // else {
        //   this.spinner.hide();
        //   swal("Datos de personal ", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async listar_usuarios() {
    this._bancantus = false;
    this.spinner.show();
    this.constants.usuario_log = JSON.parse(
      localStorage.getItem("logindatos_cas")
    );
    let request = {
      idnegocio: this.constants.usuario_log.us_idTienda,
      idpersonal: this.personal_reg.per_id,
      token: this.constants.usuario_log.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_usuarios_admin");
    this.usuario_lst = new Array<Usuario>();
    await axios
      .post(this.constants.apiUrl + "lista_usuarios_admin", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let usuario_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in usuario_lst_aux) {
            let usuario: Usuario = new Usuario(
              usuario_lst_aux[element].id,
              usuario_lst_aux[element].usuario,
              "",
              usuario_lst_aux[element].vigencia,
              0,
              0,
              this.personal_reg.per_id,
              "",
              null,
              null,
              null,
              null,
              this.personal_reg.per_apellidopaterno,
              this.personal_reg.per_apellidomaterno,
              this.personal_reg.per_nombre,
              "",
              false,
              0,
              "",
              "",
              this.personal_reg.per_email,
              this.personal_reg.per_celular
            );
            this.usuario_lst.push(usuario);
          }
          if (this.usuario_lst.length > 0) this._bancantus = true;
        }
        // else {
        //   swal("Datos de usuario ", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  agregarusuario(personal: Personal) {
    this._ver = true;
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.personal_reg = personal;
    this.reg_usuario = true;
    this.listar_usuarios();
  }
  async habilitar_deshabilitar(personal: Personal, estado: boolean) {
    this.spinner.show();
    let request = {
      id: personal.per_id,
      vigencia: estado,
      admin: 1,
    };
    console.log("TIENDA " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "actualizar_estado_personal", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "success").then(
            () => {
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async habilitar_deshabilitar_us(usuario: Usuario, estado: boolean) {
    this.spinner.show();
    let request = {
      id: usuario.us_id,
      vigencia: estado,
      admin: 1,
    };
    console.log("USUARIO " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "actualizar_estado_usuario", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "success").then(
            () => {
              this.refrescar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  editar(personal: Personal) {
    this._ver = false;
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.personal_reg = personal;
  }

  async registrar() {
    this.spinner.show();
    let request = {
      id: this.personal_reg.per_id,
      nombre: this.personal_reg.per_nombre,
      apellidopaterno: this.personal_reg.per_apellidopaterno,
      apellidomaterno: this.personal_reg.per_apellidomaterno,
      email: this.personal_reg.per_email,
      celular: this.personal_reg.per_celular,
      tienda: 0,
    };
    console.log("PERSONAL " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_personal", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "success").then(
            () => {
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async registrar_usu() {
    this.spinner.show();

    let request = {
      id: this.usuario_reg.us_id,
      usuario: this.usuario_reg.us_usuario,
      idAdmin: this.personal_reg.per_id,
      tipo: 1,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "created_user_admin", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "success").then(
            () => {
              this.cancelar_usu();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    // } else {
    //   this.spinner.hide();
    //   swal("Registro de Usuario!", this._mensajeval, "info");
    // }
  }

  cancelar() {
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this._ver = true;
    this.refrescar();
  }

  cancelar_usu() {
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this.refrescar();
    this._ver = true;
    this._verus = true;
  }
  return() {
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this._ver = true;
    this._verus = true;
    this.reg_usuario = false;
    this.reg_permiso = false;
    this.refrescar();
  }
  nuevo() {
    if (!this.reg_usuario) {
      this.personal_reg = new Personal(
        0,
        "",
        "",
        "",
        "",
        "",
        false,
        0,
        true,
        null,
        null,
        null,
        null
      );
      this._ver = false;
    } else {
      this.usuario_reg = new Usuario(
        0,
        "",
        "",
        false,
        0,
        0,
        0,
        "",
        null,
        null,
        null,
        null,
        "",
        "",
        "",
        "",
        false,
        0,
        "",
        "",
        "",
        ""
      );
      this.showgenera = true;
      this._ver = false;
      this._verus = false;
    }
  }
}
