export class Usuario {
  constructor(
    public us_id: number,
    public us_usuario: string,
    public us_password: string,
    public us_vigencia: boolean,
    public us_idAdmnistrador: number,
    public us_idProveedor: number,
    public us_idTienda: number,
    public us_codigocambio: string,
    public us_fecharegistro: Date,
    public us_fechaactualiza: Date,
    public us_adminregistro: number,
    public us_adminactualiza: number,
    public us_apellidopaterno: string,
    public us_apellidomaterno: string,
    public us_nombres: string,
    public us_token: string,
    public us_recordar: boolean,
    public us_tipo: number,
    public us_empresa: string,
    public us_epresentante: string,
    public us_email: string,
    public us_telefono: string
  ) {}
}
