import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CambioPasswordComponent } from "../cambiopassword.component";
import { Usuario } from "../../../models/usuario";
import { ConstantsService } from "../../../constants.service";
const MODALS = {
  changepass: CambioPasswordComponent,
};

@Component({
  selector: "cabecera",
  templateUrl: "./cabecera.component.html",
  styles: [
    `.modal-dialog{width: 350px !important;max-width: 350px !important;}`]
})
export class CabeceraComponent {
  @Input() usuario_cab: Usuario;
  withAutofocus = `<button type="button" ngbAutofocus class="btn btn-danger"
      (click)="modal.close('Ok click')">Ok</button>`;
  public _name = "";
  constructor(
    // public _router : ActivatedRoute,
    public _modalService: NgbModal,
    public router: Router,
    public constants: ConstantsService
  ) {
    // console.log("US " + JSON.stringify(this.constants.usuario_log));
    this.usuario_cab = this.constants.usuario_log;
  }

  public logoutadmin(): void {
    localStorage.removeItem("token_cas");
    localStorage.removeItem("logindatos_cas");
    this.router.navigate(["adminlogin"]);
  }

  open(name: string) {
    this._modalService.open(MODALS[name]);
  }
}
