import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector:'inicio',
  templateUrl:'./home.component.html',

})


export class HomeComponent{
  public title: string;
  public rute: string;
  constructor(
    // private _router : ActivatedRoute,
    private router:Router
  ){
    this.title = 'Inicio';
    this.rute = '/inicio';
  }

  // ngOnInit(){
  //   console.log('login.component cargado!');
  // }

}
