import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CambioPasswordComponent } from "../cambiopassword.component";
import { ConstantsService } from "../../../constants.service";
import { Usuario } from "../../../models/usuario";
const MODALS = {
  changepass: CambioPasswordComponent
};

@Component({
  selector: "menubus",
  templateUrl: "./menubus.component.html"
})
export class MenuBusComponent {
  @Input() usuario_men: Usuario;
  withAutofocus = `<button type="button" ngbAutofocus class="btn btn-danger"
      (click)="modal.close('Ok click')">Ok</button>`;
  public _name = "";
  constructor(
    // public _router : ActivatedRoute,
    public _modalService: NgbModal,
    public router: Router,
    public constants: ConstantsService
  ) {
    this.usuario_men = this.constants.usuario_log;
  }

  open(name: string) {
    this._modalService.open(MODALS[name]);
  }
}
