import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "src/app/constants.service";
import { Categoria } from "src/app/models/categoria";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "unidmedida",
  templateUrl: "./unidmedida.component.html",
})
export class UnidadMedidaComponent implements OnInit {
  public title: string;
  public rute: string;
  public categoria_reg: Categoria;
  public categoria_lst: Array<Categoria>;
  public categoria_fil: Array<Categoria>;
  public searchinput: string;
  public reg_categoria_b: boolean = false;
  public _ver: boolean = true;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Unidades de medida";
    this.rute = "/unidmedida";
    this.constants.sesionadmin();
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
    this.categoria_lst = new Array<Categoria>();
    this.categoria_fil = new Array<Categoria>();
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.listado_categoria();
  }

  search_unidad($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.categoria_lst = new Array<Categoria>();
    this.categoria_lst = this.categoria_fil.filter(
      (categoria: Categoria) =>
        categoria.cat_descripcion.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }
  editar($categoria: Categoria) {
    this._ver = false;
    this.categoria_reg = $categoria;
  }
  async habilitar_deshabilitar($categoria: Categoria, $estado: boolean) {
    this.spinner.show();
    let request = { id: $categoria.cat_id, vigencia: $estado };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_categoria", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de unidad de medida",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.listado_categoria();
          });
        } else {
          swal("Registro de  envase", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  cancelar() {
    this._ver = true;
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }
  nuevo() {
    this._ver = false;
    // this._edit = false;
    this.categoria_reg = new Categoria(
      0,
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null,
      0,
      "",
      ""
    );
  }

  async listado_categoria() {
    this.spinner.show();
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 2,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              // console.log("num " + element);
              this.categoria_lst.push(categoria_aux);
              this.categoria_fil = this.categoria_lst;
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        } else {
          swal("Registro de unidad de medida", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async registrar() {
    if (this.categoria_reg.cat_codigo.trim() == "") {
      swal("Registro de unidad de medida", "Ingrese código", "info");
      return false;
    }
    if (this.categoria_reg.cat_descripcion.trim() == "") {
      swal("Registro de unidad de medida", "Ingrese descirpción", "info");
      return false;
    }
    this.spinner.show();
    let request = {
      id: this.categoria_reg.cat_id,
      descripcion: this.categoria_reg.cat_descripcion,
      codigo: this.categoria_reg.cat_codigo,
      superior: 0,
      idCategoria: 2,
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_categoria", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de unidad de medida",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.cancelar();
            this.listado_categoria();
          });
        } else {
          swal("Registro de unidad de medida", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}
