import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "src/app/constants.service";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { DatePipe } from "@angular/common";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { isNumber } from "util";
import { Publicidad } from "src/app/models/publicidad";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "publicidadbus",
  templateUrl: "./publicidadbus.component.html",
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class PublicidadBusComponent {
  public title: string;
  public rute: string;
  public errsmall: boolean = false;
  public errlarge: boolean = false;
  hoveredDate: NgbDate;
  public small_log: string;
  public large_log: string;
  fromDate: NgbDate;
  toDate: NgbDate;
  public _ver: boolean = true;
  public publicidad_reg: Publicidad;
  public publicidad_lst: Array<Publicidad>;
  public publicidad_fil: Array<Publicidad>;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public datepipe: DatePipe
  ) {
    this.title = "Ingreso de Publicidad";
    this.rute = "/business/publicidad";
    this.constants.sesionbusiness();
    this.publicidad_reg = new Publicidad(
      0,
      "",
      null,
      null,
      "",
      null,
      null,
      false,
      0,
      0,
      0,
      null,
      0,
      null,
      "",
      "",
      ""
    );
    this.publicidad_lst = new Array<Publicidad>();
    this.publicidad_fil = new Array<Publicidad>();
  }

  ngOnInit() {
    console.log("login.component cargado!");
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  limpiar_adj($id: string) {
    // console.log($file);
    if ($id === "large") {
      this.publicidad_reg.img_large = null;
      if (
        this.publicidad_reg.img_large === "" ||
        this.publicidad_reg.img_large === null ||
        this.publicidad_reg.img_large === undefined
      ) {
        this.errlarge = false;
      } else {
        this.errlarge = true;
      }
    } else if ($id === "small") {
      this.publicidad_reg.img_small = null;
      if (
        this.publicidad_reg.img_small === "" ||
        this.publicidad_reg.img_small === null ||
        this.publicidad_reg.img_small === undefined
      ) {
        this.errsmall = false;
      } else {
        this.errsmall = true;
      }
    }
  }
  previewimage($file: any, $id: string) {
    if (localStorage.getItem("token_bus") !== null) {
      // let files = $file.files;

      console.log("ID " + $id);
      if ($id == "large") {
        // this.publicidad_reg.img_large = files.files;
        this.publicidad_reg.img_large = document.getElementById($id);
        this.errlarge = true;
        this.large_log = "";
      } else if ($id == "small") {
        // this.publicidad_reg.img_small = files.files;
        this.publicidad_reg.img_small = document.getElementById($id);
        this.errsmall = true;
        this.small_log = "";
      }
      console.log(this.publicidad_reg.img_large);
      console.log(this.publicidad_reg.img_small);
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/business/dashboardprov"]);
      });
    }
  }

  async listado_publicidad() {
    this.spinner.show();
    this.publicidad_lst = new Array<Publicidad>();
    this.publicidad_fil = new Array<Publicidad>();

    let request = {
      id: this.constants.usuariobus_log.us_idProveedor.toString(),
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_publicidad", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          let publicidad_lst_aux = response.data.data;
          let publicidad_aux: Publicidad;
          if (publicidad_lst_aux != null) {
            for (let element in publicidad_lst_aux) {
              console.log("num " + element);
              publicidad_aux = new Publicidad(
                publicidad_lst_aux[element].id,
                publicidad_lst_aux[element].codigo,
                publicidad_lst_aux[element].img_small,
                publicidad_lst_aux[element].img_large,
                publicidad_lst_aux[element].descripcion,
                publicidad_lst_aux[element].fechaInicio.substr(0, 10),
                publicidad_lst_aux[element].fechaFin.substr(0, 10),
                publicidad_lst_aux[element].vigencia == 1 ? true : false,
                publicidad_lst_aux[element].idProveedor,
                publicidad_lst_aux[element].idTienda,
                publicidad_lst_aux[element].adminregistro,
                publicidad_lst_aux[element].fecharegistro,
                publicidad_lst_aux[element].adminactualiza,
                publicidad_lst_aux[element].fechaactualiza,
                publicidad_lst_aux[element].empresa,
                this.constants.apiUrl +
                  "storage?ruta=" +
                  publicidad_lst_aux[element].img_large,
                this.constants.apiUrl +
                  "storage?ruta=" +
                  publicidad_lst_aux[element].img_small
              );
              this.publicidad_fil.push(publicidad_aux);
            }
          }
          this.publicidad_lst = this.publicidad_fil;
        }
        // else {
        //   swal("Registro de publicidad", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  nuevo() {
    this._ver = false;
    this.cancelar();
  }

  async guardar_publicidad() {
    this.spinner.show();
    let large_img: any = null;
    let small_img: any = null;
    console.log(this.publicidad_reg.img_large);
    console.log(this.publicidad_reg.img_small);

    if (this.publicidad_reg.descripcion.trim() == "") {
      swal(
        "Registro de publicidad",
        "Escriba la descripción de la publicidad",
        "info"
      );
      return false;
    }
    if (this.errlarge) {
      if (this.large_log == "" && this.publicidad_reg.img_large != null) {
        large_img = this.publicidad_reg.img_large.files[0];
      } else {
        large_img = null;
      }
    } else {
      swal(
        "Registro de publicidad",
        "Adjunte imagen para pantallas large: (Laptop, PC o TV)",
        "info"
      );
      return false;
    }

    if (
      this.publicidad_reg.fechaInicio == null ||
      this.publicidad_reg.fechaInicio.length < 10 ||
      this.publicidad_reg.fechaInicio.trim() == ""
    ) {
      swal("Registro de publicidad", "Seleccione fecha de inicio.", "info");
      return false;
    }

    if (
      this.publicidad_reg.fechaFin == null ||
      this.publicidad_reg.fechaFin.length < 10 ||
      this.publicidad_reg.fechaFin.trim() == ""
    ) {
      swal("Registro de publicidad", "Seleccione fecha de inicio.", "info");
      return false;
    }
    let request = new FormData();
    request.append("id", this.publicidad_reg.id.toString());
    request.append("codigo", this.publicidad_reg.codigo);
    request.append("descripcion", this.publicidad_reg.descripcion || "");
    request.append("idTienda", "0");
    request.append(
      "idProveedor",
      this.constants.usuariobus_log.us_idProveedor.toString()
    );
    request.append(
      "fechainicio",
      this.fromDate == undefined
        ? this.publicidad_reg.fechaInicio
        : this.fromDate.year +
            "-" +
            this.fromDate.month +
            "-" +
            this.fromDate.day
    ),
      request.append(
        "fechafin",
        this.toDate == undefined
          ? this.publicidad_reg.fechaFin
          : this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day
      );
    request.append("img_small", small_img);
    request.append("img_large", large_img);

    this.spinner.hide();
    await axios
      .post(this.constants.apiUrl + "registro_publicidad", request, {
        headers: this.constants.headers_formimage,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          swal("Registro de publicidad", response.data.mensaje, "success").then(
            () => {
              this.listado_publicidad();
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de publicidad", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    this.spinner.hide();
  }

  cancelar() {
    this._ver = true;
    this.publicidad_reg = new Publicidad(
      0,
      "",
      null,
      null,
      "",
      null,
      null,
      false,
      0,
      0,
      0,
      null,
      0,
      null,
      "",
      "",
      ""
    );
    this.errsmall = false;
    this.errlarge = false;
    this.small_log = "";
    this.large_log = "";
  }
  async habilitar_deshabilitar($publicidad: Publicidad, $state: boolean) {
    this.spinner.show();
    let request = { id: $publicidad.id, vigencia: $state };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_publicidad", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Publicidad", response.data.mensaje, "success").then(() => {
            this.listado_publicidad();
          });
        } else {
          swal("Publicidad", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  editar($publicidad: Publicidad) {
    this._ver = false;
    this.publicidad_reg = $publicidad;
    console.log(this.publicidad_reg.fechaInicio.toString())
    let fechainicio = this.publicidad_reg.fechaInicio.toString().split("-")
    let dateInicio  = JSON.parse(JSON.stringify({ day: parseInt(fechainicio[2]), month: parseInt(fechainicio[1]), year: parseInt(fechainicio[0])}))
    this.fromDate = dateInicio

    let fechafin = this.publicidad_reg.fechaFin.toString().split("-")
    let dateFin = JSON.parse(JSON.stringify({ day: parseInt(fechafin[2]), month: parseInt(fechafin[1]), year: parseInt(fechafin[0])}))
    this.toDate =  dateFin

    console.log(this.toDate);
    console.log(this.fromDate);
    this.large_log = this.publicidad_reg.img_large;
    this.small_log = this.publicidad_reg.img_small;
    if (this.large_log) {
      this.errlarge = true;
    }
    if (this.small_log) {
      this.errsmall = true;
    }
  }
}
