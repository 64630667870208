export class Tienda {
  constructor(
    public tda_id: number,
    public tda_nombre: String,
    public tda_idNegocio: number,
    public tda_direccion: String,
    public tda_referencia: String,
    public tda_ciudad: String,
    public tda_vigencia: boolean,
    public tda_fecharegistro: any,
    public tda_adminregistro: number,
    public tda_fechaactualiza: any,
    public tda_adminactualiza: number,
    public tda_fiado: any,
  ) {}
}
