import { ModuleWithProviders, NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { HomeComponent } from "./components/sites/home.component";
import { AdminHomeComponent } from "./components/sites/adminhome.component";
import { AdminLoginComponent } from "./components/sites/loginadmin.component";
import { CreatedUserComponent } from "./components/sites/createduser.component";
import { DashboardAdminComponent } from "./components/sites/dashboardadmin.component";
import { providerCompanyComponent } from "./components/sites/providercompany.component";
import { DashboardBusComponent } from "./components/sites/dashboardbus.component";
import { ProductBusComponent } from "./components/sites/productbus.component";
import { BenefitBusComponent } from "./components/sites/benefitbus.component";
import { ConfigBusComponent } from "./components/sites/configbus.component";
import { UserAdminComponent } from "./components/sites/useradmin.component";

import { TiendaComponent } from "./components/sites/tienda.component";
import { CategoriaComponent } from "./components/sites/categoria.component";
import { ProductoComponent } from "./components/sites/producto.component";
import { EnvaseComponent } from "./components/sites/envase.component";
import { UnidadMedidaComponent } from "./components/sites/unidmedida.component";
import { GastosComponent } from "./components/sites/gastos.component";
import { NotificacionComponent } from "./components/sites/notificacion.component";
import { NotificacionBusComponent } from "./components/sites/notificacionbus.component";
import { PublicidadComponent } from "./components/sites/publicidad.component";
import { PublicidadBusComponent } from "./components/sites/publicidadbus.component";
import { PedidoBusComponent } from "./components/sites/pedidobus.component";
import { IngresoDatosComponent } from "./components/sites/ingresodatos.component";
import { VideoAdminComponent } from "./components/sites/videoadmin.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "inicio", component: HomeComponent },
  { path: "business", component: AdminHomeComponent },
  { path: "dashboardadmin", component: DashboardAdminComponent },
  { path: "business/dashboardprov", component: DashboardBusComponent },
  { path: "business/product", component: ProductBusComponent },
  { path: "business/benefit", component: BenefitBusComponent },
  { path: "business/datosprov", component: ConfigBusComponent },
  { path: "business/publicidad", component: PublicidadBusComponent },
  { path: "business/notificacion", component: NotificacionBusComponent },
  { path: "business/pedidos", component: PedidoBusComponent },
  { path: "business/ingresodatos", component: IngresoDatosComponent },

  {
    path: "business/createduser/:codigoconfirmacion/:tipo/:id",
    component: CreatedUserComponent,
  },
  { path: "companyprovider", component: providerCompanyComponent },
  { path: "useradmin", component: UserAdminComponent },
  { path: "tiendas", component: TiendaComponent },
  { path: "categorias", component: CategoriaComponent },
  { path: "productos", component: ProductoComponent },
  { path: "envases", component: EnvaseComponent },
  { path: "unidmedida", component: UnidadMedidaComponent },
  { path: "gastos", component: GastosComponent },
  {path: "videoadmin", component: VideoAdminComponent},
  { path: "publicidad", component: PublicidadComponent },
  { path: "notificacion", component: NotificacionComponent },

  { path: "adminlogin", component: AdminLoginComponent },
  { path: "**", component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// export const AppRoutingProviders: any[] = [];
// export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
