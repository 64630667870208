import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "titulobus",
  templateUrl: "./titulobus.component.html"
})
export class TituloBusComponent {
  public _name = "";
  public titulo: string;
  public ruta: string;
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // public _router : ActivatedRoute,
    public router: Router
  ) {}
}
