import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "header_ad",
  templateUrl: "./header_ad.component.html",
})
export class HeaderADComponent {
  private _name = "";
  public titulo: string;
  public ruta: string;
  public sesion_bus: boolean = false;

  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService, //public cabecera : CabeceraComponent
    public spinner: NgxSpinnerService
  ) {
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );
    if (this.constants.usuariobus_log == undefined) {
      this.sesion_bus = false;
    } else {
      this.sesion_bus = true;
    }
  }
}
