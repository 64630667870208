import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "provider",
  templateUrl: "./providercompany.component.html",
})
export class providerCompanyComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipoorg: number = 1;
  public usuariocreaorg: number = -1;
  public organizaciones: Array<Organizacion>;
  public organizaciones_fil: Array<Organizacion>;
  public organizacion_reg: Organizacion;
  public searchinput: string = "";
  public _bancant: boolean = false;
  public _banregistro: boolean = false;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Organizaciones";
    this.rute = "companyprovider";
    this.constants.sesionadmin();
    this.organizaciones = new Array<Organizacion>();
    this.organizaciones_fil = new Array<Organizacion>();
  }

  ngOnInit() {
    console.log("companyprovider.component cargado!");
    this.listar_organizaciones();
    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
    this.constants.listado_ciudades();
  }

  search_organizacion($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.organizaciones = new Array<Organizacion>();
    this.organizaciones = this.organizaciones_fil.filter(
      (organizacion: Organizacion) =>
        organizacion.org_empresa.toLocaleLowerCase().indexOf($txt) !== -1
    );
    if (this.organizaciones.length > 0) {
      this._bancant = true;
    } else {
      this._bancant = false;
    }
  }

  limpiar_listados_org() {
    this.organizaciones = new Array<Organizacion>();
    this.organizaciones_fil = new Array<Organizacion>();
    this._bancant = false;
  }

  async listar_organizaciones() {
    this.spinner.show();
    this.limpiar_listados_org();
    let request = {};
    if (this.usuariocreaorg.toString() == "-1") {
      request = {
        tipo: this.tipoorg,
      };
    } else {
      request = {
        tipo: this.tipoorg,
        usuariocreado: this.usuariocreaorg,
      };
    }
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_proveedor", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let organizaciones_lst_aux = response.data.data;
        let organizacion_aux: Organizacion;
        if (response.data.estado) {
          for (let element in organizaciones_lst_aux) {
            //console.log("num " + element);
            organizacion_aux = new Organizacion(
              organizaciones_lst_aux[element].id,
              organizaciones_lst_aux[element].empresa,
              organizaciones_lst_aux[element].representante,
              organizaciones_lst_aux[element].email,
              organizaciones_lst_aux[element].telefono,
              organizaciones_lst_aux[element].negocio,
              organizaciones_lst_aux[element].otrosector,
              organizaciones_lst_aux[element].sector,
              organizaciones_lst_aux[element].pais,
              organizaciones_lst_aux[element].ciudad,
              organizaciones_lst_aux[element].codigopostal,
              organizaciones_lst_aux[element].proveedor,
              organizaciones_lst_aux[element].auspiciador,
              organizaciones_lst_aux[element].patrocinador,
              organizaciones_lst_aux[element].vigencia == 1 ? true : false,
              organizaciones_lst_aux[element].mensaje,
              organizaciones_lst_aux[element].codigoconfirmacion,
              organizaciones_lst_aux[element].usuariocreado == 1 ? true : false,
              organizaciones_lst_aux[element].fechavalidacion,
              organizaciones_lst_aux[element].adminvalidacion,
              organizaciones_lst_aux[element].fechaactualiza,
              organizaciones_lst_aux[element].adminactualiza
            );
            this.organizaciones_fil.push(organizacion_aux);
          }
          this.organizaciones = this.organizaciones_fil;
          if (this.organizaciones.length > 0) {
            this._bancant = true;
          } else {
            this._bancant = false;
          }
        } else {
          swal("Registro de organizaciones", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  editar($organizacion: Organizacion) {
    console.log($organizacion);
    this.organizacion_reg = $organizacion;
    this._banregistro = true;
  }
  cancelar_registro() {
    this._banregistro = false;
    this.listar_organizaciones();
  }
  async enviar_user($organizacion: Organizacion) {
    this.spinner.show();
    this.organizacion_reg = $organizacion;
    let request = { id: this.organizacion_reg.org_id, tipo: this.tipoorg };
    await axios
      .post(this.constants.apiUrl + "send_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de Organización",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.listar_organizaciones();
            this._banregistro = false;
          });
        } else {
          swal("Registro de Organización", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async habilitar_deshabilitar($organizacion: Organizacion, $estado: boolean) {
    this.spinner.show();
    this.organizacion_reg = $organizacion;
    let request = { id: this.organizacion_reg.org_id, vigencia: $estado };
    await axios
      .post(this.constants.apiUrl + "estado_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de Organización",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.listar_organizaciones();
            this._banregistro = false;
          });
        } else {
          swal("Registro de Organización", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async actualizar_organizacion() {
    this.spinner.show();

    let request = {};
    if (this.tipoorg.toString() == "1" || this.tipoorg.toString() == "0") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        negocio: this.organizacion_reg.org_negocio,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        codigopostal: this.organizacion_reg.org_codigopostal,
        tipo: 1,
      };
    } else if (this.tipoorg.toString() == "2") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        sector: this.organizacion_reg.org_sector,
        otrosector: this.organizacion_reg.org_otrosector,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        codigopostal: this.organizacion_reg.org_codigopostal,
        tipo: 2,
      };
    } else if (this.tipoorg.toString() == "3") {
      request = {
        id: this.organizacion_reg.org_id,
        empresa: this.organizacion_reg.org_empresa,
        representante: this.organizacion_reg.org_representante,
        telefono: this.organizacion_reg.org_telefono,
        email: this.organizacion_reg.org_email,
        pais: this.organizacion_reg.org_pais,
        ciudad: this.organizacion_reg.org_ciudad,
        mensaje: this.organizacion_reg.org_mensaje,
        tipo: 3,
      };
    }
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de Organización",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.listar_organizaciones();
            this._banregistro = false;
          });
        } else {
          swal("Registro de Organización", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}
