import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "src/app/constants.service";
import { Notificacion } from "src/app/models/notificacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "notificacionbus",
  templateUrl: "./notificacionbus.component.html",
})
export class NotificacionBusComponent implements OnInit {
  public title: string;
  public rute: string;
  public notificacion_reg: Notificacion;
  public notificacion_lst: Array<Notificacion>;
  public notificacion_fil: Array<Notificacion>;
  public searchinput: string;
  public _ver: boolean = true;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Notificaciones";
    this.rute = "/business/notificacion";
    this.constants.sesionbusiness();
    this.notificacion_reg = new Notificacion(
      0,
      null,
      "",
      "",
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      ""
    );
    this.notificacion_lst = new Array<Notificacion>();
    this.notificacion_fil = new Array<Notificacion>();
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.listado_notificacion();
  }

  search_notificacion($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.notificacion_lst = new Array<Notificacion>();
    this.notificacion_lst = this.notificacion_fil.filter(
      (notificacion: Notificacion) =>
        notificacion.asunto.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }
  nuevo() {
    this._ver = false;
  }
  async enviar() {
    if (this.notificacion_reg.asunto.trim() == "") {
      swal("Notificaciones 2", "Ingrese asunto", "info");
      return false;
    }
    if (this.notificacion_reg.mensaje.trim() == "") {
      swal("Notificaciones 2", "Ingrese mensaje", "info");
      return false;
    }
    this.spinner.show();
    console.log("PROVEEDOR USUARIO " + this.constants.usuariobus_log);
    let request = {
      id: this.notificacion_reg.id,
      asunto: this.notificacion_reg.asunto,
      mensaje: this.notificacion_reg.mensaje,
      idProveedor: this.constants.usuariobus_log.us_idProveedor.toString(),
      idTienda: 0,
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_notificacion", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Notificaciones", response.data.mensaje, "success").then(() => {
            this.cancelar();
            this.listado_notificacion();
          });
        } else {
          swal("Notificaciones", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  cancelar() {
    this._ver = true;
    this.notificacion_reg = new Notificacion(
      0,
      null,
      "",
      "",
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      ""
    );
  }
  editar($notificacion: Notificacion) {
    this._ver = false;
    this.notificacion_reg = $notificacion;
  }
  async listado_notificacion() {
    this.spinner.show();
    this.notificacion_lst = new Array<Notificacion>();

    let request = {
      id: this.constants.usuariobus_log.us_idProveedor.toString(),
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_notificacion", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log("notifiaciones \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let notificacion_lst_aux = response.data.data;
            for (let element in notificacion_lst_aux) {
              // console.log("num " + element);
              this.notificacion_lst.push(notificacion_lst_aux[element]);
              this.notificacion_fil = this.notificacion_lst;
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        // else {
        //   swal("Notificaciones", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async habilitar_deshabilitar($notificacion: Notificacion, $state: boolean) {
    this.spinner.show();
    let request = { id: $notificacion.id, vigencia: $state };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_notificacion", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Notificaciones", response.data.mensaje, "success").then(() => {
            this.listado_notificacion();
          });
        } else {
          swal("Notificaciones", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}
