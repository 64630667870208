import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConstantsService } from "../../constants.service";
import { Usuario } from "../../models/usuario";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "cambio-passwordbus",
  templateUrl: "./cambiopasswordbus.component.html",
  styles: [
    `.modal-dialog{width: 350px !important;}`]

})
export class CambioPasswordBusComponent {
  public clave_actual: string;
  public clave_nueva: string;
  public confirmar_clave: string;
  public headers: Headers;
  public usuario_ls: Usuario;
  constructor(
    public modal: NgbActiveModal,
    public constants: ConstantsService,
    public router: Router
  ) {
    this.headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    });
  }

  public async change_password_click() {
    if (localStorage.getItem("token_bus") !== null) {
      let request = {
        claveActual: this.clave_actual,
        claveNueva: this.clave_nueva,
        confirmarClave: this.confirmar_clave,
        token: localStorage.getItem("token_bus"),
      };
      console.log(request);
      await axios
        .post(this.constants.apiUrl + "change_passwordbus", request,{
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          console.log(response.data);
          let estado = response.data.estado;

          swal(response.data.mensaje, {
            icon: response.data.estado === true ? "success" : "error",
          }).then(() => {
            if (response.data.estado) {
              this.usuario_ls = JSON.parse(
                localStorage.getItem("logindatos_bus")
              );
              this.usuario_ls.us_password = "";
              localStorage.setItem(
                "logindatos_bus",
                JSON.stringify(this.usuario_ls)
              );
              localStorage.removeItem("token_bus");
              this.modal.dismiss("Cross click");
              location.href = this.constants.index+"#/business";
            }
          });
        })
        .catch((error: any) => {
          swal(error.message, {
            icon: "error",
          });
        });
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        localStorage.removeItem("logindatos_bus");
        this.router.navigate(["/adminlogin"]);
      });
    }
  }
}
