import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConstantsService } from "src/app/constants.service";
import { Usuario } from "src/app/models/usuario";
import { Organizacion } from "src/app/models/organizacion";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import axios from "axios";
import { Subject } from "rxjs";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { debounceTime } from "rxjs/operators";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent {
  validatingForm: FormGroup;
  private _name = "";
  public titulo: string;
  public ruta: string;
  public usuario_reg: Usuario;
  public organizacion_reg: Organizacion;
  public closeResult = "";
  public _enviar: boolean = false;
  successMessage = "";
  public typealert: string = "";
  private _success = new Subject<string>();
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    private modalService: NgbModal
  ) {
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      true,
      0,
      0,
      0,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );

    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
  }

  ngOnInit() {
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl("", Validators.email),
      loginFormModalPassword: new FormControl("", Validators.required),
    });

    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(5000))
      .subscribe(() => (this.successMessage = ""));
      this.constants.listado_ciudades();
  }

  get loginFormModalEmail() {
    return this.validatingForm.get("loginFormModalEmail");
  }

  get loginFormModalPassword() {
    return this.validatingForm.get("loginFormModalPassword");
  }

  open(content) {
    this.organizacion_reg = new Organizacion(
      0,
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "Perú",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      null,
      null,
      null
    );
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title",  windowClass : "modal-dialog-cb" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async RegistroTienda(captchaResponse: string, $tipo: number) {
    // var name = $scope.negocio_prov;
    // console.log("name is " + name);

    // VALIDAR RESGITRO
    this.typealert = "warning";
    if (this.organizacion_reg.org_empresa.trim() == "") {
      this._success.next("Ingrese nombre de negocio");
      return false;
    }

    if (this.organizacion_reg.org_representante.trim() == "") {
      this._success.next("Ingrese nombre de representante");
      return false;
    }

    if (this.organizacion_reg.org_telefono.trim() == "") {
      this._success.next("Ingrese teléfono o móvil");
      return false;
    }

    if (this.organizacion_reg.org_email.trim() == "") {
      this._success.next("Ingrese email");
      return false;
    }

    if (this.organizacion_reg.org_pais.trim() == "") {
      this._success.next("Ingrese su país");
      return false;
    }

    if (this.organizacion_reg.org_ciudad.trim() == "") {
      this._success.next("Ingrese su ciudad");
      return false;
    }

    // if (this.organizacion_reg.org_mensaje.trim() == "") {
    //   this._success.next("Ingrese mensaje");
    //   return false;
    // }
    this._enviar = true;

    let request = {
      empresa: this.organizacion_reg.org_empresa,
      representante: this.organizacion_reg.org_representante,
      telefono: this.organizacion_reg.org_telefono,
      email: this.organizacion_reg.org_email,
      pais: this.organizacion_reg.org_pais,
      ciudad: this.organizacion_reg.org_ciudad,
      mensaje: this.organizacion_reg.org_mensaje,
      tipo: $tipo,
      tokenrecaptcha: captchaResponse,
    };
    console.log("REGISTRO TIENDA: " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_proveedor", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this._enviar = false;
          swal("Registro de Tienda", response.data.mensaje, "success").then(
            () => {
              this.modalService.dismissAll();
            }
          );
        } else {
          this._enviar = false;
          swal("Registro de Tienda", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }

  async Contactanos(captchaResponse: string) {
    // var name = $scope.negocio_prov;
    // console.log("name is " + name);
    this.typealert = "warning";
    if (this.organizacion_reg.org_empresa.trim() == "") {
      this._success.next("Ingrese nombre de negocio");
      return false;
    }

    if (this.organizacion_reg.org_representante.trim() == "") {
      this._success.next("Ingrese nombre de representante");
      return false;
    }

    if (this.organizacion_reg.org_email.trim() == "") {
      this._success.next("Ingrese email");
      return false;
    }

    // if (this.organizacion_reg.org_mensaje.trim() == "") {
    //   this._success.next("Ingrese mensaje");
    //   return false;
    // }
    this._enviar = true;
    let request = {
      empresa: this.organizacion_reg.org_empresa,
      representante: this.organizacion_reg.org_representante,
      telefono: this.organizacion_reg.org_telefono,
      email: this.organizacion_reg.org_email,
      pais: this.organizacion_reg.org_pais,
      ciudad: this.organizacion_reg.org_ciudad,
      mensaje: this.organizacion_reg.org_mensaje,
      tokenrecaptcha: captchaResponse,
    };

    console.log("CONTACTANOS: " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "contactanos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "success").then(() => {
            this.modalService.dismissAll();
          });
        } else {
          this._enviar = false;
          swal("Contáctanos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this._enviar = false;
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }

  olvideclave(){
    if (this.usuario_reg.us_tipo!= 0) {
        location.href = this.constants.indextienda +'#/olvideclave?tipo=' + this.usuario_reg.us_tipo
    }else{
      swal("Recuperar Contraseña", "Seleccione tipo de cuenta.", "info")
    }

  }
}
