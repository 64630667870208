import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "src/app/constants.service";
import { Categoria } from "src/app/models/categoria";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Ubigeo } from "src/app/models/ubigeo";
import { HorarioEntrega } from "src/app/models/horarioentrega";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "ingresodatos",
  templateUrl: "./ingresodatos.component.html",
})
export class IngresoDatosComponent implements OnInit {
  public title: string;
  public rute: string;
  public horarioentrega_reg: HorarioEntrega;
  public horarioentrega_lst: Array<HorarioEntrega>;
  public horarioentrega_fil: Array<HorarioEntrega>;
  public searchinput: string;
  public reg_categoria_b: boolean = false;
  public _ver: boolean = true;

  public departamento:string = "0";
  public provincia:string = "0";
  public distrito:string = "0";
  public dia:string = "0";
  public timeinicio:string;
  public timefin:string;
  public departamento_lst:Array<Ubigeo>
  public provincia_lst:Array<Ubigeo>
  public distrito_lst:Array<Ubigeo>
  constructor(
    // private _router : ActivatedRoute,
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Horarios de Entrega";
    this.rute = "/ingresodatos";
    this.constants.sesionbusiness();



    this.departamento_lst = new Array<Ubigeo>();
    this.provincia_lst = new Array<Ubigeo>();
    this.distrito_lst = new Array<Ubigeo>();
    this.horarioentrega_lst = new Array<HorarioEntrega>();
    this.limpiar();
    this.ubigeo(0, "PERÚ");
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.constants.usuariobus_log = JSON.parse(
      localStorage.getItem("logindatos_bus")
    );
    this.listado_categoria();
  }

  search_unidad($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.horarioentrega_lst = new Array<HorarioEntrega>();
    this.horarioentrega_lst = this.horarioentrega_fil.filter(
      (horarioentrega: HorarioEntrega) =>
      horarioentrega.departamento.toLocaleLowerCase().indexOf($txt) !== -1 || horarioentrega.provincia.toLocaleLowerCase().indexOf($txt) !== -1 || horarioentrega.distrito.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }
  editar($categoria: HorarioEntrega) {
    this._ver = false;
    this.horarioentrega_reg = $categoria;
    this.ubigeo(1,this.horarioentrega_reg.departamento)
    this.ubigeo(2,this.horarioentrega_reg.provincia)
  }

  limpiar(){
    this.timefin = "";
    this.timeinicio = "";
    this.departamento="0";
    this.provincia="0";
    this.distrito="0";
    this.dia = "0";
    this.provincia_lst = new Array<Ubigeo>();
    this.distrito_lst = new Array<Ubigeo>();
    this.horarioentrega_reg = new HorarioEntrega(0,0,"0","0","","","",true,"0","0","0")
  }
  async deshabilitar($categoria: HorarioEntrega) {
    this.spinner.show();
    let request = { id: $categoria.id, vigencia: 0 };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "estado_horarioentrega", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de horario de entrega",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.listado_categoria();
          });
        } else {
          swal("Registro de  horario de entrega", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  cancelar() {
    this._ver = true;
    this.limpiar();
  }
  nuevo() {
    this._ver = false;
    this.limpiar()
  }

  async ubigeo($tipo:number, $filtro:string){

    if($tipo <2){
      this.provincia_lst = new Array<Ubigeo>();
      this.distrito_lst = new Array<Ubigeo>();
      this.provincia = "0"
      this.distrito = "0"
      }
      if($tipo == 2){
        this.distrito = "0"
        this.distrito_lst = new Array<Ubigeo>();
        }
    this.spinner.show();
    let request = {
      tipo: $tipo,
      filtro: $filtro,
    };

    await axios
      .post(this.constants.apiUrl + "cbo_ubigeo", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              if($tipo == 0){
              this.departamento_lst.push(new Ubigeo(categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion));
              }
              if($tipo == 1){
                this.provincia_lst.push(new Ubigeo(categoria_lst_aux[element].codigo,
                  categoria_lst_aux[element].descripcion));
                }
                if($tipo == 2){
                  this.distrito_lst.push(new Ubigeo(categoria_lst_aux[element].codigo,
                    categoria_lst_aux[element].descripcion));
                  }
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });

  }
  async listado_categoria() {
    this.spinner.show();
    this.horarioentrega_lst = new Array<HorarioEntrega>();

    let request = {
      idProveedor: this.constants.usuariobus_log.us_idProveedor,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_horarioentrega", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("horario entrega \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let horarioentrega_aux = response.data.data;
            for (let element in horarioentrega_aux) {
              // console.log("num " + element);

              // console.log("num " + element);
              this.horarioentrega_lst.push(horarioentrega_aux[element]);
              this.horarioentrega_fil = this.horarioentrega_lst;
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async registrar() {
    if (this.horarioentrega_reg.ubigeo == "0") {
      swal("Registro de horario de entrega", "Seleccione ubigeo", "info");
      return false;
    }
    if (this.horarioentrega_reg.dia == "0") {
      swal("Registro de horario de entrega", "Seleccione día", "info");
      return false;
    }

    if (this.horarioentrega_reg.horainicio == "") {
      swal("Registro de horario de entrega", "Ingrese hora a iniciar entrega.", "info");
      return false;
    }
    if (this.horarioentrega_reg.horafin == "") {
      swal("Registro de horario de entrega", "Ingrese hora a finalizar entrega.", "info");
      return false;
    }
    this.spinner.show();
    let request = {
      id: this.horarioentrega_reg.id,
      idProveedor: this.constants.usuariobus_log.us_idProveedor,
      dia: this.horarioentrega_reg.dia,
      ubigeo: this.horarioentrega_reg.ubigeo,
      horainicio: this.horarioentrega_reg.horainicio,
      horafin: this.horarioentrega_reg.horafin,
      vigencia: 1
    };
    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_horarioentrega", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal(
            "Registro de horario de entrega",
            response.data.mensaje,
            "success"
          ).then(() => {
            this.cancelar();
            this.listado_categoria();
          });
        } else {
          swal("Registro de horario de entrega", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
}

